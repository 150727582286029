import * as React from "react";
import { AppContext, ToastAdded, useProject, useTeam } from "@/context";
import { colors, StyleSheet } from "@/utils/style";
import { UploadDocs } from "./UploadDocs";
import { ViewDocs } from "./ViewDocs";
import CryptoJS from "crypto-js";
interface Document {
  id: number;
  filename: string;
  path: string;
  type: string;
}
export const RiskPage: React.FC = () => {
  const encryptedToken = CryptoJS.AES.encrypt(
    process.env.API_KEY,
    process.env.SECRET_KEY
  ).toString();
  const header = {
    headers: {
      Authorization: `Bearer ${encryptedToken}`,
    },
  };
  const [documents, setDocuments] = React.useState<Document[]>([]);
  const { dispatch, state } = React.useContext(AppContext);
  const isSuperUser = state.config.user.is_superuser;
  const team = useTeam();
  React.useEffect(() => {
    getCompaniesDocuments();
  }, []);

  const getCompaniesDocuments = async () => {
    const DocsUrl = `${process.env.KUYUA_APP_BACKEND_URL}/docs/all?slug=${team.slug}`;
    const response = await fetch(DocsUrl, header);
    const data = await response.json();

    if (data.data && Array.isArray(data.data)) {
      setDocuments(
        data.data.map((doc) => ({
          id: doc.file_id,
          filename: doc.filename,
          path: doc.path,
          type: doc.file_type,
        }))
      );
    } else {
      setDocuments([data.data]);
    }
  };
  const deleteHandler = async (doc) => {
    if (!confirm("are you sure you want to delete this file")) {
      return;
    }
    const url = `${process.env.KUYUA_APP_BACKEND_URL}/docs/delete?fileId=${doc.id}`;
    const response = await fetch(url, {
      method: "DELETE",
      headers: header.headers,
    });
    const result = await response.json();
    if (result.status === 200) {
      dispatch({
        type: ToastAdded,
        toast: {
          kind: "success",
          text: result.message || "File deleted successfully.",
        },
      });
      await getCompaniesDocuments();
    } else {
      dispatch({
        type: ToastAdded,
        toast: {
          kind: "success",
          text: result.message || "Error deleting file",
        },
      });
      await getCompaniesDocuments();
    }
  };
  const handleUploadSuccess = async () => {
    await getCompaniesDocuments();
  };
  return (
    <div className="riskPage" style={styles.wrapper}>
      {isSuperUser && <UploadDocs onUploadSuccess={handleUploadSuccess} />}

      <ViewDocs documents={documents} deleteHandler={deleteHandler} />
    </div>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    width: "50%",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    top: 125,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    gap: 5,
    zIndex: 10,
  },
  tableWrapper: {
    width: "100%",
    backgroundColor: colors.darkBlue,
    border: `1px solid ${colors.brightBlue}`,
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
  },
});
