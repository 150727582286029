import * as React from 'react';
import { colors, style, StyleSheet } from "@/utils/style";
import { isThreatenedSpecies, isProtectedSpecies, isKeyStoneSpecies, t } from "@/utils";
import { StaticMap } from "@/lib/map";
import Dropdown from "@/utils/Dropdown";
import {booleanSort, getSortBy, SortOrder, speciesRoleSort, stringSort} from "@/utils/sort";
import { TableHeaderCell } from "@/lib";
import { SiteSpeciesItem } from "@/project/site/species/index";
import './index.scss';
import Checkbox from "@/utils/Checkbox";
import CryptoJS from 'crypto-js';
const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();
const header = {
  headers: {
    'Authorization': `Bearer ${encryptedToken}`
  }
};
const siteRadius = parseInt(localStorage.getItem("siteRadius"));
console.log("site radius species page -> ", siteRadius);
interface SiteOverviewProps {
  site: INewSiteDetailed
  bbox: number[]
  layers: string[]
  onLayerLoad: (id: string, layer: IKeyAreaLayer) => void,
  onClick: (species: ISpecies, what: 'info' | 'map') => void
}

type SortType = 'species' | 'umbrella' | 'keystone' | 'protected' | 'threatened';

const SpeciesRoleDropdownItems = [
  { id: -1, label: t('ui.all'), apiLabel: '_all' },
  { id: 3, group: t('ui.habitat_role'), label: t('ui.apex_predator'), apiLabel: 'apex predator' },
  { id: 4, group: t('ui.habitat_role'), label: t('ui.habitat_creator'), apiLabel: 'habitat creators' },
  { id: 5, group: t('ui.habitat_role'), label: t('ui.seed_disperser'), apiLabel: 'seed dispersers' },
  { id: 6, group: t('ui.habitat_role'), label: t('ui.other'), apiLabel: 'other' },
];

export const SpeciesPage: React.FC<SiteOverviewProps> = ({ site, bbox, layers, onLayerLoad, onClick }) => {

  //const threatenedSpecies = site.species.filter(s => isThreatenedSpecies(s)).length;
  const protectedSpecies = site.species.filter(s => isProtectedSpecies(s)).length;
  //const keystoneSpecies = site.species.filter(s => isKeyStoneSpecies(s)).length;
  const [keystoneSpecies, setKeystoneSpeciesLength] = React.useState(0);
  const [threatenedSpecies, setThreatenedSpecies] = React.useState(0);
  const [totalSpecies, setSpeciesTotal] = React.useState(0);
  const [staticMapPins, setStaticMapPins] = React.useState([{center: site.latlng, id: 'site-' + site.id, text: site.name ?? site.site_id}]);
  console.log("Outer site range -->", siteRadius);
  React.useEffect(() => {
    if (localStorage.getItem('totalSpecies') && totalSpecies === 0) {
      const _totalSpecies = JSON.parse(localStorage.getItem('totalSpecies'));
      const _threatenedSpecies = JSON.parse(localStorage.getItem('threatenedSpecies'));
      const _keystoneSpecies = JSON.parse(localStorage.getItem('keystoneSpecies'));

      setSpeciesTotal(_totalSpecies);
      setKeystoneSpeciesLength(_keystoneSpecies);
      setThreatenedSpecies(_threatenedSpecies);
    }
  }, [totalSpecies]);
  return (
    <div style={{ display: 'flex', flexDirection: 'row', flex: 1, gap: 30 }}>

      <div style={styles.wrapper}>
        <SpeciesTable site={site} onClick={onClick} sharedState={staticMapPins} setSharedState={setStaticMapPins} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, gap: 20 }}>
        <div style={styles.summaryHeader}>
          <HeaderKpi label={t('ui.total_species')} value={totalSpecies} />
          <HeaderKpi label={t('ui.threatened_species')} value={threatenedSpecies} />
          {/* <HeaderKpi label={t('ui.protected_species')} value={protectedSpecies} /> */}
          <HeaderKpi label={t('ui.keystone_species')} value={keystoneSpecies} />
        </div>
        <div style={{ position: 'relative', minHeight: 500 }}>
          <StaticMap
            bbox={bbox}
            center={site.latlng}
            circles={[
              { center: site.latlng, id: `site-${site.id}-inner`, radius: siteRadius/4 },
              { center: site.latlng, id: `site-${site.id}-outer`, radius: siteRadius },
            ]}
            layers={layers}
            onLayerLoad={onLayerLoad}
            pins={staticMapPins}            
            rasters={{}}
            zoom={7} />
        </div>
      </div>
    </div>
  );
};

const SpeciesTable: React.FC<{site: INewSiteDetailed, onClick:(species: ISpecies, what: 'info' | 'map') => void, sharedState, setSharedState}> = ({ site, onClick, sharedState, setSharedState }) => {

  const [sortType, setSortType] = React.useState<SortType>('species');
  const [sortOrder, setSortOrder] = React.useState<SortOrder>('desc');

  const [speciesTypeIds, setSpeciesTypeIds] = React.useState<number[]>([]);
  const [roleIds, setRoleIds] = React.useState<number[]>([]);
  const speciesTypes = [{ id: 1, label: t('ui.protected_species')}, { id: 2, label: t('ui.threatened_species')}];
  const [speciesAlldata, setSpecies] = React.useState({});
  const fetchSpecies = async () => {
    //query species from GBIF server for now until we migrate the whole geo-server to the new server
     const radiusByKm = Number(siteRadius) / 1000 || 20;
    const url = `https://geoserver-staging.kuyua.net/api/species?decimalLatitude=${site.latlng.lat}&decimalLongitude=${site.latlng.lng}&rangeByKM=${radiusByKm}`
    //https://geoserver-staging.kuyua.net
    // const url = `${process.env.GEO_SERVER_URL}/species?decimalLatitude=${site.latlng.lat}&decimalLongitude=${site.latlng.lng}`
    console.log(url);
    try {
      const response = await fetch(url, header);
      const data = await response.json();
      console.log('species ===>>> ', data);
      if(data && data.total > 0) {
        setSpecies(data);
      }

    } catch (error) {
      console.error('Error fetching species:', error);
    }
  }
  React.useEffect(() => {
    fetchSpecies();
  }, []);
  const onSort = (order: SortOrder, type: SortType) => {
    setSortType(type);
    setSortOrder(order);
  };
  const conservation_statuses = ['CR', 'EN', 'VU'];

  const filteredSpeciesList = React.useMemo(() => {

    if(speciesAlldata && speciesAlldata.total > 0){
      //let species = [].concat(speciesAlldata?.species).filter(s => s.is_keystone_species === true);
      let species = [];
      const seenTaxonKeys = new Set();

      speciesAlldata?.species
        .filter(s => s.is_keystone_species === true)
        .forEach(s => {
          if (!seenTaxonKeys.has(s.taxonKey)) {
            species.push(s);
            seenTaxonKeys.add(s.taxonKey);
          }
        });
      console.log('species after filter XXXXXXXX ', species.length);
  
      if (speciesTypeIds.length > 0) {
  
        const selectedSpeciesLabels = speciesTypeIds.map(id => speciesTypes.find(c => c.id === id)?.label);
  
        if (selectedSpeciesLabels.includes(t('ui.protected_species'))) {
          species = species.filter(s => !conservation_statuses.includes(s.conservation_status));
        }
  
        if (selectedSpeciesLabels.includes(t('ui.threatened_species'))) {
          species = species.filter(s => conservation_statuses.includes(s.conservation_status));
        }
      }
  
      const selectedRoleLabels = SpeciesRoleDropdownItems
          .filter(selectedRole => roleIds.includes(selectedRole.id))
          .map(filteredRole => filteredRole.apiLabel);
  
      let filteredSpecies = selectedRoleLabels.length === 0 ? species : []
  
      selectedRoleLabels.forEach((selectedRoleLabel) => {
        
        if (['apex predator', 'habitat creators', 'seed dispersers'].includes(selectedRoleLabel)) {
          filteredSpecies = filteredSpecies.concat(species.filter(s => s.key_stone_species_category === selectedRoleLabel));
          console.log('filteredSpecies INSIDE XXXXXXXX ', filteredSpecies);
        } else if (selectedRoleLabel === 'other') {
          filteredSpecies = filteredSpecies.concat(species.filter(s => s.is_keystone_species === false));
        }
      });
  
      species = filteredSpecies;
      
  
      return species.sort((a, b) => {
        if (sortType === 'species') {
          return stringSort(a.name, b.name, sortOrder);
        }
        else if (sortType === 'protected') {
          return booleanSort(!conservation_statuses.includes(a.conservation_status), !conservation_statuses.includes(b.conservation_status), sortOrder);
        }
        else if (sortType === 'threatened') {
          return booleanSort(conservation_statuses.includes(a.conservation_status), conservation_statuses.includes(b.conservation_status), sortOrder);
        } else {
          return speciesRoleSort(a, b, sortOrder);
        }
      });
    }

  }, [speciesTypeIds, speciesAlldata, roleIds, sortOrder, sortType]);

  

  const handleSetRole = (ids: number[]): void => {

    const oldSelect = roleIds;
    const newSelect = ids.filter(newId => !oldSelect.includes(newId));

    if (ids.length === 0 || newSelect.includes(-1)) {

      setRoleIds([]);
    } else {

      setRoleIds(ids)
    }
  };

  const handleSetSpeciesType = (selectedId: number, check: boolean): void => {

    if (check) {

      setSpeciesTypeIds(prev => [...prev, selectedId]);
    } else {

      setSpeciesTypeIds(speciesTypeIds.filter(id => id !== selectedId));
    }
  };
  const OverrideStaticMap = (specie): void => {
    
    const relatedSpecies = speciesAlldata?.species
    .filter(s => s.taxonKey === specie.taxonKey)
    .map(s => ({
      center: { lat: s.decimalLatitude, lng: s.decimalLongitude },
      id: `related-specie-${s.id}`,
      color: '#800080', // Purple color for the dots
      text: s.name,
      radius: 1,
      isPriorityLocation: true
    }));
    const points = [{center: site.latlng, id: 'site-' + site.id, text: site.name ?? site.site_id},...relatedSpecies];
    //{center: site.latlng, id: 'site-' + site.id, text: site.name ?? site.site_id}
    if(points.length > 0){
      setSharedState(points);
    }
  }
  const HandleSpecieRank = (specie): String => {
    if (specie.key_stone_species_category === "apex predator") {
      return t('ui.apex_predator');
    }
    else if (specie.key_stone_species_category === "habitat creators") {
      return t('ui.habitat_creator');
    }
    else if (specie.key_stone_species_category === "seed dispersers") {
      return t('ui.seed_disperser');
    } else {
      return t('ui.other');
    }
  }
  return (
    <>
    <div style={{ ...style.flexRow, ...styles.filterBar }}>
      <Dropdown
        placeholder={'key-stone species'}
        dropdownStyle={{ padding: '5px 10px', marginBottom: 'unset', fontSize: 13, height: 'unset' }}
        listDropdownStyle={{ top: "unset" }}
        handler={handleSetRole}
        options={SpeciesRoleDropdownItems}
        selected={roleIds}
        flat
        multiple
        selectedRight
      />
      <div style={{ ...style.flexRow, justifyContent: "right", flex: 2 }}>
          {speciesTypes.map((species, i) => (
            <div id={"species_" + species.id} style={{ marginRight: 10 }}>
              <Checkbox
                checked={speciesTypeIds.includes(species.id)}
                label={species.label}
                checkHandler={(check:boolean) => handleSetSpeciesType(species.id, check)}
              />
            </div>
          ))}
      </div>
    </div>

      <div className={'table-container'}>
        <table className="s-table s-table-fixed species-table">
          <thead>
          <tr>
            <TableHeaderCell
              onClick={(arg: SortOrder) => onSort(arg, 'species')}
              sortedBy={getSortBy(sortType, 'species', sortOrder)}
              label={t('ui.species')}
            />
            <TableHeaderCell
              label={t('ui.keystone_species')}
              onClick={(arg: SortOrder) => onSort(arg, 'keystone')}
              sortedBy={getSortBy(sortType, 'keystone', sortOrder)}
            />
            <TableHeaderCell
              label={t('ui.protected_species')}
              onClick={(arg: SortOrder) => onSort(arg, 'protected')}
              sortedBy={getSortBy(sortType, 'protected', sortOrder)}
            />
            <TableHeaderCell
              label={t('ui.threatened_species')}
              onClick={(arg: SortOrder) => onSort(arg, 'threatened')}
              sortedBy={getSortBy(sortType, 'threatened', sortOrder)}
              textAlign={'end'}
            />
            <TableHeaderCell
              label={t('Distance (KM)')}
              // onClick={(arg: SortOrder) => onSort(arg, 'threatened')}
              // sortedBy={getSortBy(sortType, 'threatened', sortOrder)}
              textAlign={'end'}
            />
          </tr>
          </thead>
          <tbody>
                  {filteredSpeciesList ? (
          filteredSpeciesList.map((s) => 
            <tr>
              <td>
                <div>
                  <a className="s-link" onClick={() => OverrideStaticMap(s)}>{s.name}</a>
                </div>
                <div className="s-table-sublabel">{s.common_name || '—'}</div>
              </td>
              <td>
                <div style={{ textAlign: "center" }}>{HandleSpecieRank(s)}</div>
                <div className="s-table-sublabel">&nbsp;</div>
              </td>
              <td style={{ textAlign: "center" }}>
                <Checkbox checked={!conservation_statuses.includes(s.conservation_status)} readonly={true} checkHandler={() => { }} />
              </td>
              <td style={{ textAlign: "center" }}>
                <Checkbox checked={conservation_statuses.includes(s.conservation_status)} readonly={true} checkHandler={() => { }} />
              </td>
              <td>
                <div style={{ textAlign: "center" }}>{s.distanceByKm.toFixed(1)}</div>
                <div className="s-table-sublabel">&nbsp;</div>
              </td>
            </tr>
          )
        ) : (
          <span className="spinner"></span>
        )}

          </tbody>
        </table>
      </div>
  </>
  );
};


export const HeaderKpi: React.FC<{ label: string, value: number | string }> = ({ label, value }) => {

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 10, alignItems: 'center' }}>
      <span style={{ fontSize: 12, textTransform: 'uppercase' }}>{label}</span>
      <span style={{ fontSize: 30, color: colors.brightBlue }}>{value}</span>
    </div>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    width: '50%',
    borderRadius: 10,
    border: `1px solid ${colors.brightBlue}`,
    padding: 10,
    backgroundColor: colors.darkBlue,
  },
  summaryHeader: {
    height: 90,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.darkBlue,
    borderRadius: 10,
    padding: '5px 20px',
  },
  filterBar: {
    borderRadius: 10,
    backgroundColor: colors.opaqueWhite,
    padding: 10,
    alignItems: 'center',
    gap: 10,
  },
  export: {
    height: '100%',
    borderRadius: 20,
    backgroundColor: 'transparent',
    opacity: 1,
    padding: '5px 10px',
    color: colors.white,
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    border: `1px solid ${colors.white}`,
    fontSize: 13,
  },
});
