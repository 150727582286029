import * as React from 'react';
import { colors, style, StyleSheet } from "@/utils/style";
import {LoadingAnimation } from '@/lib';
import CryptoJS from "crypto-js";
import './socialIndicators.scss';

const years = ['2021', '2022', '2023'];
const mapSanctionsColor = (value: any) => {
    if (value === 0 ) return 'green'; 
    if (parseFloat(value) > 0) return 'red'; 
    return 'green'; 
};
const mapCorruptionColor = (value: any) => {
    switch (true) {
        case value >= 0.66 && value < 1:
          return 'green';
        case value >= 0.51 && value < 0.66:
          return 'yellow';
        case value >= 0.35 && value < 0.51:
          return 'orange';
        case value >= 0 && value < 0.35:
          return 'red';
        default:
          return 'gray';
      }
};
const mapLawEnforcementColor = (value: any) => {
    switch (true) {
        case value >= 0.67 && value < 1:
          return 'green';
        case value >= 0.51 && value < 0.67:
          return 'yellow';
        case value >= 0.35 && value < 0.51:
          return 'orange';
        case value >= 0 && value < 0.35:
          return 'red';
        default:
          return 'gray';
      }
};
const mapArmedConflitColor = (value: any) => {
    switch (true) {
        case value >= 0 && value < 3:
          return 'green';
        case value >= 3 && value < 17:
          return 'yellow';
        case value >= 17 && value < 143:
          return 'orange';
        case value >= 143:
          return 'red';
        default:
          return 'gray';
      }
};
const mapDataFalsificationColor = (value: any) => {
    switch (true) {
        case value >= 0 && value < 0.37:
          return 'green';
        case value >= 0.37 && value < 0.51:
          return 'yellow';
        case value >= 0.51 && value < 0.66:
          return 'orange';
        case value >= 0.66 && value < 1:
          return 'red';
        default:
          return 'gray';
      }
};
//TODO: increase wrapper css class width to be near 96%  in the SiteDetailPage
const SocialIndicators = ({address}:any) => {
    const encryptedToken = CryptoJS.AES.encrypt(
        process.env.API_KEY,
        process.env.SECRET_KEY
      ).toString();
      const header = {
        headers: {
          Authorization: `Bearer ${encryptedToken}`,
        },
      };
    const [data, setData] = React.useState({}); 
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    
    React.useEffect(() => {
        const fetchSocialIndicators = async () => {
            console.log("fetch social");

            const url = `${process.env.KUYUA_APP_BACKEND_URL}/social-indicators?country=${address.country}&country_code=${address.country_code}`;
            try 
            {
              const response = await fetch(url, header);
              const data = await response.json();
              console.log("data", data);
              
              if (data) 
              {
                setIsLoading(false);
                setData(data);
              }
            } 
            catch (error) 
            {
              console.error("Error fetching social indicators:", error);
            }
            finally
            {
                setIsLoading(false)
            }
          };
        fetchSocialIndicators();
    }, [address]); 

    return (
        <>
            {isLoading && <LoadingAnimation />}
            <div className="social-metrics-container">
                <div className="left-panel">
                    <h3 style={{margin:0}}>SOCIAL</h3>
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>2021</th>
                                <th>2022</th>
                                <th>2023</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Control Of Corruption</td>
                                {years.map((year, idx) => (
                                    <td key={idx}>
                                        <div className="circle" style={{ backgroundColor: mapCorruptionColor(data?.total_wgi_data?.[0]?.[`corruption_${year}`]) }}></div>
                                        <div>{(Math.trunc(data?.total_wgi_data?.[0]?.[`corruption_${year}`] * 100) / 100) || "*"}</div>
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>Level Of Law Enforcement</td>
                                {years.map((year, idx) => (
                                    <td key={idx}>
                                        <div className="circle" style={{ backgroundColor: mapLawEnforcementColor(data?.total_wgi_data?.[0]?.[`law_enforcement_${year}`]) }}></div>
                                        <div>{(Math.trunc(data?.total_wgi_data?.[0]?.[`law_enforcement_${year}`] * 100) / 100) || "*"}</div>
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>Data Falsification Risk</td>
                                {years.map((year, idx) => (
                                    <td key={idx}>
                                        <div className="circle" style={{ backgroundColor: mapDataFalsificationColor(data?.total_wgi_data?.[0]?.[`data_falsification_risk_${year}`]) }}></div>
                                        <div>{(Math.trunc(data?.total_wgi_data?.[0]?.[`data_falsification_risk_${year}`] * 100) / 100) || "*"}</div>
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>Human Rights</td>
                                {years.map((year, idx) => (
                                    <td key={idx}>
                                        <div className="circle" style={{ backgroundColor: mapCorruptionColor(data?.human_rights?.[idx]?.civil_liberties_index) }}></div>
                                        <div>{(Math.trunc(data?.human_rights?.[idx]?.civil_liberties_index * 100) / 100) || "*"}</div>
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>Armed Conflict Events</td>
                                {years.map((year, idx) => (
                                    <td key={idx}>
                                        <div className="circle" style={{ backgroundColor: mapArmedConflitColor(data?.armed_conflicts?.[idx]?.events) }}></div>
                                        <div>{data?.armed_conflicts?.[idx]?.events || "*"}</div>
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>Sanctions</td>
                                {years.map((year, idx) => (
                                    <td key={idx}>
                                        <div className="circle" style={{ backgroundColor: mapSanctionsColor(data?.sanctions?.[idx]?.number_of_sanctioned_entities)}}></div>
                                        <div>{data?.sanctions?.[idx]?.number_of_sanctioned_entities || "*"}</div>
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>Risk Of Circumvention</td>
                                {years.map((year, idx) => (
                                    <td key={idx}>
                                        <div className="circle" style={{ backgroundColor: "gray"}}></div>
                                        <div></div>
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>EU Designated Risk</td>
                                {years.map((year, idx) => (
                                    <td key={idx}>
                                        <div className="circle" style={{ backgroundColor: "gray"}}></div>
                                        <div></div>
                                    </td>
                                ))}
                            </tr>
                           
                        </tbody>
                    </table>
                    <div className="circle-legend ">
                        <div className="circle green"></div> Low 
                        <div className="circle yellow"></div> Medium 
                        <div className="circle orange"></div> High 
                        <div className="circle red"></div> Very High 
                        <div className="circle gray"></div> In Development 
                    </div>
                    <div className="no-data">* Data for 2023 is not yet available</div> 
                </div>
            </div>
        </>
      );
};

export default SocialIndicators;