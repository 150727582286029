import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import CryptoJS from "crypto-js";
import useFetchImpacts from "@/utils/useFetchImpacts";
import useFetchDependencies from "@/utils/useFetchDependencies";
import { AppContext, ToastAdded, useTeam } from "@/context";
import { t } from "@/utils";
import { colors } from "@/utils/style";

// YN Styles
const containerStyle = {
  border: `1px solid ${colors.brightBlue}`,
  height: "100%",
  color: "white",
  padding: 20,
  borderRadius: 15,
  fontSize: "12px", // Updated font size
};

const sectionStyle = {
  fontSize: "12px", // Updated font size
  fontWeight: "bold",
};

const flexRowStyle = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  fontSize: "12px", // Updated font size
};

const encryptedToken = CryptoJS.AES.encrypt(
  process.env.API_KEY,
  process.env.SECRET_KEY
).toString();

const header = {
  headers: {
    Authorization: `Bearer ${encryptedToken}`,
  },
};

const rating = {
  VH: "Very High",
  H: "High",
  M: "Medium",
  L: "Low",
} as const;

type Rating = (typeof rating)[keyof typeof rating];

interface Answer {
  value: string;
  comment: string;
  questionLabel: string;
}

interface Answers {
  dependencies: { [key: string]: Answer };
  impacts: { [key: string]: Answer };
  yes_no: { [key: string]: Answer };
  comparing: {
    [key: string]: { [year: string]: Answer } & { questionLabel: string };
  };
}

const buttonStyle = {
  cursor: "pointer",
};

const SiteInputTab: React.FC<{ site: INewSiteDetailed }> = React.memo(({ site }) => {
  const team = useTeam();
  const [answers, setAnswers] = useState<Answers>({
    dependencies: {},
    impacts: {},
    yes_no: {},
    comparing: {},
  });

  const [economicActivitiesIds, setEconomicActivitiesIds] =
    useState<string>("");
  const [newQuestionToAdd, setNewQuestionToAdd] = useState<string>("");
  const [newYNQuestionToAdd, setNewYNQuestionToAdd] = useState<string>("");
  const [YNQuestions, setYNQuestions] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [comparingQuestions, setComparingQuestions] = useState<
    Array<{ id: string; 2023: string; 2024: string; label: string }>
  >([]);

  const { dispatch, state } = useContext(AppContext);
  const superUser = state.config.user.is_superuser;

  const [editingQuestionId, setEditingQuestionId] = useState<string | null>(null);
  const [editedQuestions, setEditedQuestions] = useState<{ [key: string]: string }>({});

  // Fetch the site data and economic activity IDs
  const getSite = useCallback(async () => {
    try {
      const newDbUrl = `${process.env.KUYUA_APP_BACKEND_URL}/sites?company_id=${team.id}&site_name=${site.name}`;
      const response = await fetch(newDbUrl, header);
      const data = await response.json();
      const siteData = data.sites[0];

      const economicActivityIds = siteData.economic_activities
        .map((ea: any) => ea.economic_activity_id)
        .join(",");
      setEconomicActivitiesIds(economicActivityIds);
    } catch (error) {
      console.error("Error fetching site data:", error);
      dispatch({
        type: ToastAdded,
        toast: { kind: "error", text: t("ui.failed_to_load_sites") },
      });
    }
  }, [team.id, site.name, dispatch]);

  // Fetch and submit questions
  const submitAnswers = useCallback(
    async (answers: Answers) => {
      function deleteQuestionLabel(obj: { [k: string]: any }) {
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            if (key === "questionLabel") {
              delete obj[key];
            } else if (typeof obj[key] === "object" && obj[key] !== null) {
              deleteQuestionLabel(obj[key]);
            }
          }
        }
        return obj;
      }

      const payload = deleteQuestionLabel(answers);

      fetch(
        `${process.env.KUYUA_APP_BACKEND_URL}/site-template/answers/${site.id}`,
        {
          method: "POST",
          body: JSON.stringify({
            answers: payload,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${encryptedToken}`,
          },
        }
      )
        .then(async (res) => {
          if (res.ok)
            dispatch({
              type: ToastAdded,
              toast: {
                kind: "success",
                text: "Changes saved successfully",
              },
            });
          else {
            const { errors } = await res.json();
            errors?.forEach((error: string) =>
              dispatch({
                type: ToastAdded,
                toast: {
                  kind: "error",
                  text: error,
                },
              })
            );
          }
        })
        .catch((error) => console.error("Error submitting questions:", error));
    },
    [answers, site.name, site.id]
  );

  const submitQuestion = useCallback(
    (question: string, type: string = "comparing") => {
      fetch(`${process.env.KUYUA_APP_BACKEND_URL}/site-template/questions`, {
        method: "POST",
        body: JSON.stringify({
          question,
          type,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${encryptedToken}`,
        },
      })
        .then(async (res) => {
          if (res.ok) {
            dispatch({
              type: ToastAdded,
              toast: {
                kind: "success",
                text: "Changes saved successfully",
              },
            });
            getQuestions();
          } else {
            const { errors } = await res.json();
            errors?.forEach((error: string) =>
              dispatch({
                type: ToastAdded,
                toast: {
                  kind: "error",
                  text: error,
                },
              })
            );
          }
        })
        .catch((error) => console.error("Error submitting questions:", error));
    },
    []
  );

  const deleteQuestion = useCallback(
    (questionId: string) => {
      fetch(`${process.env.KUYUA_APP_BACKEND_URL}/site-template/questions/${questionId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${encryptedToken}`,
        },
        body: JSON.stringify({
          site_id: site.id,
        })
      })
        .then(async (res) => {
          if (res.ok) {
            dispatch({
              type: ToastAdded,
              toast: {
                kind: "success",
                text: "Question deleted successfully",
              },
            });
            getQuestions();
          } else {
            const { errors } = await res.json();
            errors?.forEach((error: string) =>
              dispatch({
                type: ToastAdded,
                toast: {
                  kind: "error",
                  text: error,
                },
              })
            );
          }
        })
        .catch((error) => console.error("Error deleting question:", error));
    },
    []
  );

  // Fetch existing questions template
  const getQuestions = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.KUYUA_APP_BACKEND_URL}/site-template/answers/${site.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${encryptedToken}`,
          },
        }
      );
      const { result } = await response.json();

      if (result) {
        const yesNoQuestions = Object.keys(result.answers?.yes_no || {}).map(
          (qid) => ({
            label: result.answers.yes_no[qid].questionLabel,
            id: qid,
          })
        );
        const comparingQuestions = Object.keys(result.answers?.comparing || {}).map(
          (qid) => ({
            id: qid,
            2023: result.answers.comparing[qid]["2023"].comment,
            2024: result.answers.comparing[qid]["2024"].comment,
            label: result.answers.comparing[qid].questionLabel,
          })
        );

        setYNQuestions(yesNoQuestions);
        setComparingQuestions(comparingQuestions);

        setAnswers(result.answers);
      }
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  }, [site.name, site.id]);

  useEffect(() => {
    if (site) {
      getSite();
      getQuestions();
    }
  }, [site, getSite, getQuestions]);

  // Fetch impacts and dependencies data using custom hooks
  const { restructuredImpactsGeoServer } = useFetchImpacts(
    economicActivitiesIds
  );
  const { restructuredDependenciesGeoServer } = useFetchDependencies(
    economicActivitiesIds
  );

  const [impactQuestions, dependencyQuestions] = useMemo(() => {
    const impactQs = restructuredImpactsGeoServer?.flatMap((im: any) =>
      im?.values?.map((val: any) => ({
        value: rating[im.rating as keyof typeof rating],
        label: val,
      }))
    ) || [];

    const dependencyQs = restructuredDependenciesGeoServer?.flatMap((dep: any) =>
      dep?.values?.map((val: any) => ({
        value: rating[dep.rating as keyof typeof rating],
        label: val,
      }))
    ) || [];

    return [impactQs, dependencyQs];
  }, [restructuredImpactsGeoServer, restructuredDependenciesGeoServer]);

  const handleApply = async (question: string, id: any) => {
    try {
      await fetch(
        `${process.env.KUYUA_APP_BACKEND_URL}/site-template/questions/${id}`,
        {
          method: "PUT",
          body: JSON.stringify({
            question,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${encryptedToken}`,
          },
        }
      );

      dispatch({
        type: ToastAdded,
        toast: {
          kind: "success",
          text: "Changes saved successfully",
        },
      });

      getQuestions();
    } catch (error) {
      console.error("Error submitting questions:", error);
    }
  };

  // Select options component
  const SelectOptions = React.memo(({ options, onChange, value }: {
    options: string[];
    onChange: (value: string) => void;
    value: string;
  }) => (
    <select
      onChange={(e) => onChange(e.target.value)}
      style={{
        width: "100%",
        borderRadius: 10,
        height: 30,
        background: "transparent",
        color: "white",
        fontSize: "12px", // Updated font size
      }}
      value={value}
      disabled={superUser}
    >
      {options.map((option) => (
        <option style={{ color: "black" }} key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  ));

  const formatName = useCallback((name: string) => {
    return name
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }, []);

  return (
    <div>
      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <button
          onClick={() => submitAnswers(answers)}
          style={{
            ...buttonStyle,
            width: 200,
            borderRadius: 15,
            background: "transparent",
            color: "white",
            border: `1px solid ${colors.brightBlue}`,
            padding: "5px 10px",
            fontSize: "12px", // Updated font size
          }}
        >
          Save
        </button>
      </div>
      <br />
      <div style={{ display: "flex", gap: 15 }}>
        <div style={{ ...containerStyle, width: "70%" }}>
          <div style={{ display: "flex" }}>
            <div style={{ width: "25%" }} />
            <div
              style={{
                width: "70%",
                display: "flex",
                justifyContent: "center",
                gap: 10,
              }}
            >
              <div style={{ width: "5%" }}>Potential</div>
              <div style={{ width: "15%", marginLeft: 30 }}>Actual</div>
              <div style={{ width: "80%" }}>Comments</div>
            </div>
          </div>
          <br />
          <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            <div style={sectionStyle}>Impacts</div>
            <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
              {impactQuestions.map((impact) => (
                // Inline InputRow component
                <div key={impact.label} style={flexRowStyle}>
                  <div style={{ width: "25%", fontWeight: "normal", whiteSpace: "wrap" }}>
                    {formatName(impact.label)}
                  </div>
                  <div style={{
                    width: "70%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 10,
                  }}>
                    <div style={{ width: "5%", whiteSpace: "nowrap", fontSize: "12px" }}>
                      {impact.value}
                    </div>
                    <div style={{ width: "15%", marginLeft: 30 }}>
                      <SelectOptions
                        options={["Low", "Medium", "High", "Very High"]}
                        onChange={(value) =>
                          setAnswers(prev => ({
                            ...prev,
                            impacts: {
                              ...prev.impacts,
                              [formatName(impact.label)]: {
                                questionLabel: formatName(impact.label),
                                value,
                                comment: prev.impacts?.[formatName(impact?.label)]?.comment || "",
                              },
                            },
                          }))
                        }
                        value={answers?.impacts?.[formatName(impact?.label)]?.value || impact?.value}
                      />
                    </div>
                    <div style={{ width: "80%" }}>
                      <textarea
                        disabled={superUser}
                        onChange={(e) => {
                          const newComment = e.target.value;
                          setAnswers(prev => ({
                            ...prev,
                            impacts: {
                              ...prev.impacts,
                              [formatName(impact.label)]: {
                                ...prev.impacts?.[formatName(impact.label)],
                                comment: newComment,
                              },
                            },
                          }));
                        }}
                        value={answers?.impacts?.[formatName(impact?.label)]?.comment || ""}
                        style={{
                          borderRadius: 10,
                          padding: 0,
                          height: 30,
                          width: '100%',
                          background: "transparent",
                          color: "white",
                          fontSize: "12px", // Updated font size
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <br />
          <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            <div style={sectionStyle}>Dependencies</div>
            <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
              {dependencyQuestions.map((dep) => (
                <div key={dep.label} style={flexRowStyle}>
                  <div style={{ width: "25%", fontWeight: "normal", whiteSpace: "wrap" }}>
                    {formatName(dep.label)}
                  </div>
                  <div style={{
                    width: "70%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 10,
                  }}>
                    <div style={{ width: "5%", whiteSpace: "nowrap", fontSize: "12px" }}>
                      {dep.value}
                    </div>
                    <div style={{ width: "15%", marginLeft: 30 }}>
                      <SelectOptions
                        options={["Low", "Medium", "High", "Very High"]}
                        onChange={(value) =>
                          setAnswers(prev => ({
                            ...prev,
                            dependencies: {
                              ...prev.dependencies,
                              [formatName(dep.label)]: {
                                questionLabel: formatName(dep.label),
                                value,
                                comment: prev.dependencies?.[formatName(dep.label)]?.comment || "",
                              },
                            },
                          }))
                        }
                        value={answers?.dependencies?.[formatName(dep?.label)]?.value || dep?.value}
                      />
                    </div>
                    <div style={{ width: "80%" }}>
                      <textarea
                        disabled={superUser}
                        onChange={(e) => {
                          const newComment = e.target.value;
                          setAnswers(prev => ({
                            ...prev,
                            dependencies: {
                              ...prev.dependencies,
                              [formatName(dep.label)]: {
                                ...prev.dependencies?.[formatName(dep.label)],
                                comment: newComment,
                              },
                            },
                          }));
                        }}
                        value={answers?.dependencies?.[formatName(dep?.label)]?.comment || ""}
                        style={{
                          borderRadius: 10,
                          padding: 0,
                          height: 30,
                          width: '100%',
                          background: "transparent",
                          color: "white",
                          fontSize: "12px", // Updated font size
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div
          style={{
            width: "30%",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            gap: 15,
          }}
        >
          <div
            style={{
              ...containerStyle,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            {YNQuestions.map((question) => {
              const onChange = (data: any) => {
                setAnswers((prev) => ({
                  ...prev,
                  yes_no: {
                    ...prev.yes_no,
                    [question.id]: {
                      ...data,
                    },
                  },
                }));
              };

              const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
                const newComment = e.target.value;
                onChange({
                  questionLabel: question.label,
                  value: answers.yes_no?.[question.id]?.value,
                  comment: newComment,
                });
              };

              const isEditing = editingQuestionId === question.id;
              const editedQuestion = editedQuestions[question.id] || question.label;

              return (
                <div key={question.id} style={{ display: "flex", alignItems: "flex-start", width: "100%" }}>
                  <div style={{
                    width: superUser ? "100%" : "50%",
                    fontWeight: "bold",
                    ...(superUser && {
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }),
                  }}>
                    {isEditing ? (
                      <textarea
                        value={editedQuestion}
                        onChange={(e) => setEditedQuestions({ ...editedQuestions, [question.id]: e.target.value })}
                        style={{
                          borderRadius: 10,
                          padding: 0,
                          height: 30,
                          width: 150,
                          background: "transparent",
                          color: "white",
                          fontSize: "12px", // Updated font size
                        }}
                      />
                    ) : (
                      <text>{question.label}</text>
                    )}
                    {superUser && <div style={{ display: "flex", gap: 5 }}  >
                      {(
                        isEditing ? (
                          <button
                            onClick={async () => {
                              await handleApply(editedQuestion, question.id);
                              setEditingQuestionId(null);
                            }}
                            style={{
                              ...buttonStyle,
                              background: "transparent",
                              color: "white",
                              borderRadius: 15,
                              border: `1px solid ${colors.brightBlue}`,
                              padding: "0px 10px",
                              fontSize: "12px", // Updated font size
                            }}
                          >
                            Apply
                          </button>
                        ) : (
                          <button
                            onClick={() => setEditingQuestionId(question.id)}
                            style={{
                              ...buttonStyle,
                              background: "transparent",
                              color: "white",
                              borderRadius: 15,
                              border: `1px solid ${colors.brightBlue}`,
                              padding: "0px 10px",
                              fontSize: "12px", // Updated font size
                            }}
                          >
                            Edit
                          </button>
                        )
                      )}
                      <button
                        onClick={() => deleteQuestion(question.id)}
                        style={{
                          ...buttonStyle,
                          background: "transparent",
                          color: "white",
                          borderRadius: 15,
                          border: `1px solid ${colors.brightBlue}`,
                          padding: "0px 10px",
                          fontSize: "12px", // Updated font size
                        }}
                      >
                        Delete
                      </button>
                    </div>}
                  </div>
                  {!superUser && (
                    <div style={{ display: "flex", width: "50%", gap: 5 }}>
                      <div style={{ width: "50%" }}>
                        <SelectOptions
                          onChange={(value) =>
                            onChange({
                              questionLabel: question.label,
                              value,
                              comment: answers["yes_no"]?.[question.id]?.comment,
                            })
                          }
                          options={["Yes", "No"]}
                          value={answers["yes_no"]?.[question.id]?.value}
                        />
                      </div>
                      <div style={{ width: "50%" }}>
                        <textarea
                          onChange={handleCommentChange}
                          value={answers["yes_no"]?.[question.id]?.comment || ""}
                          style={{
                            borderRadius: 10,
                            padding: 0,
                            height: 30,
                            width: "100%",
                            background: "transparent",
                            color: "white",
                            fontSize: "12px", // Updated font size
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
            {superUser && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <label>Question</label>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <textarea
                    onChange={async (e) => {
                      setNewYNQuestionToAdd(e.target.value);
                    }}
                    placeholder="Add a new question"
                    name=""
                    value={newYNQuestionToAdd}
                    style={{
                      borderRadius: 10,
                      padding: 0,
                      height: 30,
                      width: 150,
                      background: "transparent",
                      color: "white",
                      fontSize: "12px", // Updated font size
                    }}
                  />
                  <button
                    style={{
                      ...buttonStyle,
                      width: "30%",
                      background: "transparent",
                      color: "white",
                      borderRadius: 15,
                      border: `1px solid ${colors.brightBlue}`,
                      fontSize: "12px", // Updated font size
                    }}
                    onClick={async () => {
                      await submitQuestion(newYNQuestionToAdd, "yes_no");
                      setNewYNQuestionToAdd("");
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            )}
          </div>

          <div style={{ ...containerStyle, width: "100%" }}>
            {!superUser && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "50%" }}></div>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    gap: 5,
                    justifyContent: "flex-end",
                  }}
                >
                  <div style={{ width: "50%" }}>2023</div>
                  <div style={{ width: "50%" }}>2024</div>
                </div>
              </div>
            )}

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                {comparingQuestions.length !== 0 &&
                  comparingQuestions.map((qs) => {
                    const isEditing = editingQuestionId === qs.id;
                    const editedQuestion = editedQuestions[qs.id] || qs.label;

                    return (
                      <div
                        key={qs.id}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div style={{ width: superUser ? "100%" : "50%" }}>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                              {superUser && "• "}
                              {isEditing ? (
                                <textarea
                                  value={editedQuestion}
                                  onChange={(e) => setEditedQuestions({ ...editedQuestions, [qs.id]: e.target.value })}
                                  style={{
                                    borderRadius: 10,
                                    padding: 0,
                                    height: 30,
                                    width: 150,
                                    background: "transparent",
                                    color: "white",
                                    fontSize: "12px",
                                  }}
                                />
                              ) : (
                                <text>{qs.label}</text>
                              )}
                            </div>

                            {superUser && (
                              <div style={{ display: "flex", gap: 5 }}>
                                {isEditing ? (
                                  <button
                                    onClick={async () => {
                                      await handleApply(editedQuestion, qs.id);
                                      setEditingQuestionId(null);
                                    }}
                                    style={{
                                      ...buttonStyle,
                                      background: "transparent",
                                      color: "white",
                                      borderRadius: 15,
                                      border: `1px solid ${colors.brightBlue}`,
                                      padding: "0px 10px",
                                      fontSize: "12px",
                                    }}
                                  >
                                    Apply
                                  </button>
                                ) : (
                                  <button
                                    onClick={() => setEditingQuestionId(qs.id)}
                                    style={{
                                      ...buttonStyle,
                                      background: "transparent",
                                      color: "white",
                                      borderRadius: 15,
                                      border: `1px solid ${colors.brightBlue}`,
                                      padding: "0px 10px",
                                      fontSize: "12px",
                                    }}
                                  >
                                    Edit
                                  </button>
                                )}
                                <button
                                  onClick={() => deleteQuestion(qs.id)}
                                  style={{
                                    ...buttonStyle,
                                    background: "transparent",
                                    color: "white",
                                    borderRadius: 15,
                                    border: `1px solid ${colors.brightBlue}`,
                                    padding: "0px 10px",
                                    fontSize: "12px",
                                  }}
                                >
                                  Delete
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                        {!superUser && (
                          <div
                            style={{
                              width: "50%",
                              display: "flex",
                              gap: 5,
                              justifyContent: "flex-end",
                            }}
                          >
                            <div>
                              <textarea
                                onChange={(e) => {
                                  setAnswers((prev) => ({
                                    ...prev,
                                    comparing: {
                                      ...prev.comparing,
                                      [qs.id]: {
                                        ...prev.comparing?.[qs.id],
                                        2023: {
                                          ...prev.comparing?.[qs.id]?.["2023"],
                                          comment: e.target.value,
                                        },
                                      },
                                    },
                                  }));
                                }}
                                value={
                                  answers?.comparing?.[qs.id]?.["2023"]
                                    ?.comment || ""
                                }
                                style={{
                                  borderRadius: 10,
                                  padding: 0,
                                  height: 30,
                                  width: "100%",
                                  background: "transparent",
                                  color: "white",
                                  fontSize: "12px", // Updated font size
                                }}
                              />
                            </div>
                            <div>
                              <textarea
                                onChange={(e) => {
                                  setAnswers((prev) => ({
                                    ...prev,
                                    comparing: {
                                      ...prev?.comparing,
                                      [qs.id]: {
                                        ...prev?.comparing?.[qs.id],
                                        2024: { comment: e.target.value },
                                      },
                                    },
                                  }));
                                }}
                                value={
                                  answers?.comparing?.[qs.id]?.["2024"].comment
                                }
                                style={{
                                  borderRadius: 10,
                                  padding: 0,
                                  height: 30,
                                  width: "100%",
                                  background: "transparent",
                                  color: "white",
                                  fontSize: "12px", // Updated font size
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                {superUser && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      marginTop: "10px",
                    }}
                  >
                    <label>Question</label>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <textarea
                        onChange={async (e) => {
                          setNewQuestionToAdd(e.target.value);
                        }}
                        placeholder="Add a new question"
                        name=""
                        value={newQuestionToAdd}
                        style={{
                          borderRadius: 10,
                          padding: 0,
                          height: 30,
                          width: 150,
                          background: "transparent",
                          color: "white",
                          fontSize: "12px", // Updated font size
                        }}
                      />
                      <button
                        onClick={async () => {
                          await submitQuestion(newQuestionToAdd);
                          setNewQuestionToAdd("");
                        }}
                        style={{
                          ...buttonStyle,
                          width: "30%",
                          background: "transparent",
                          color: "white",
                          borderRadius: 15,
                          border: `1px solid ${colors.brightBlue}`,
                          fontSize: "12px", // Updated font size
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default SiteInputTab;