import React, { useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import {LoadingAnimation } from '@/lib';
import CryptoJS from 'crypto-js';



const BSAMap = ({ site, onBSAChange }) => {
    const [selectedBSA, setSelectedBSA] = React.useState({});
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    function addMarker (map, lng, lat) {
         //add a marker after creating the map
        const marker = new mapboxgl.Marker()
        .setLngLat([lng, lat])
        .addTo(map);
        //set the new center
        map.setCenter([lng, lat]);
        marker.setLngLat([lng, lat]);
    }

    const [isAlertOpen, setAlertOpen] = React.useState(false);

    const showAlert = () => {
        setAlertOpen(true);
    };

    const closeAlert = () => {
        setAlertOpen(false);
    };


    function createCircle(map:any, lng:number, lat:number, radiusInM:number, points = 64) {
        const radiusInKm = radiusInM / 1000;
        const output = [];
        const dx = radiusInKm / (111.320 * Math.cos(lat * Math.PI / 180));
        const dy = radiusInKm / 110.574;
        let theta, x, y;
    
        for (let i = 0; i < points; i++) {
            theta = (i / points) * (2 * Math.PI);
            x = dx * Math.cos(theta);
            y = dy * Math.sin(theta);
    
            output.push([lng + x, lat + y]);
        }
    
        output.push(output[0]); // Close the loop
    
        const circleSourceData =  {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: [{
                    type: 'Feature',
                    properties: {},
                    geometry: {
                        type: 'Polygon',
                        coordinates: [output],
                    },
                }],
            },
        };

        map.addSource('circle-radius', circleSourceData);

                map.addLayer({
                    id: 'circle-radius-fill',
                    type: 'fill',
                    source: 'circle-radius',
                    paint: {
                        'fill-color': 'blue',
                        'fill-opacity': 0.1
                    }
                });

                // Optionally, add a border to the circle for better visibility
                map.addLayer({
                    id: 'circle-radius-outline',
                    type: 'line',
                    source: 'circle-radius',
                    paint: {
                        'line-color': 'blue',
                        'line-opacity': 0.5,
                        'line-width': 2
                    }
                });


    }

    useEffect(() => {
        setIsLoading(true);
        mapboxgl.accessToken = 'pk.eyJ1IjoiYW5kcmUtaGlja21hbm4iLCJhIjoiY2xoNjR4enBkMDE3cjNqcGc0aG93ZzlueSJ9.JH3ClP3oIf2uvc4ZpFvjJQ';
        const map = new mapboxgl.Map({
            container: 'map', // container ID
            style: 'mapbox://styles/mapbox/streets-v11', // style URL
            center: [site.latlng.lng, site.latlng.lat], // starting position [lng, lat]
            zoom: 12 // starting zoom
        });
    
        const loadMapData = async () => {
            map.on('load', async () => {
                console.log('site.parameters.inner_range_meters --->>>> ', site.parameters.inner_range_meters);
                const radius = site.parameters.inner_range_meters || 2000; // Radius in meters
                // const radius = 30000; 
                const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();
                const url = `${process.env.GEO_SERVER_URL}/protected-areas/all?radius=${radius}&lng=${site.latlng.lng}&lat=${site.latlng.lat}`;
                try {
                    const response = await fetch(url, {
                        headers: {
                            'Authorization': `Bearer ${encryptedToken}`
                        }
                    });
                    const { radiusFiltered, closestDistanceMeters, coverageAreaM2 } = await response.json();
                    console.log('BSA res ===>>> ', { radiusFiltered, closestDistanceMeters, coverageAreaM2 });
                    onBSAChange({ radiusFiltered, closestDistanceMeters, coverageAreaM2 });
    
                    // Radius Filtered areas
                    map.addSource('radiusFiltered', {
                        type: 'geojson',
                        data: radiusFiltered
                    });
    
                    map.addLayer({
                        id: 'radiusFiltered-fill',
                        type: 'fill',
                        source: 'radiusFiltered',
                        paint: {
                            'fill-color': 'pink',
                            'fill-opacity': 0.7
                        }
                    });
    
                    // Closest Distance Marker in pink and line
                    if (closestDistanceMeters && closestDistanceMeters.name) {
                        const closestPoint = [closestDistanceMeters.longitude, closestDistanceMeters.latitude];
                        // const closestPoint = [closestDistanceMeters.latitude, closestDistanceMeters.longitude]; 
    
                        // Draw a line from the selected location to the closest area
                        map.addSource('lineToClosest', {
                            type: 'geojson',
                            data: {
                                type: 'Feature',
                                properties: {},
                                geometry: {
                                    type: 'LineString',
                                    coordinates: [[site.latlng.lng, site.latlng.lat], closestPoint]
                                }
                            }
                        });
    
                        map.addLayer({
                            id: 'lineToClosest',
                            type: 'line',
                            source: 'lineToClosest',
                            layout: { 'line-join': 'round', 'line-cap': 'round' },
                            paint: { 'line-color': 'red', 'line-width': 4 }
                        });
                    }
    
                    // Display the coverage area 
                    if (coverageAreaM2) {
                        console.log(`Coverage Area ==== ${coverageAreaM2} square meters`);
                        // You can display this information in the UI rather than drawing a circle
                    }

                     // Add interactive features
                map.on('click', 'radiusFiltered-fill', (e) => {

                    if (e.features.length > 0) {
                        
                        showAlert();
                        setSelectedBSA(e.features[0].properties);
                        
                    }
                });

                map.on('mouseenter', 'radiusFiltered-fill', () => {
                    map.getCanvas().style.cursor = 'pointer';
                });

                map.on('mouseleave', 'radiusFiltered-fill', () => {
                    map.getCanvas().style.cursor = '';
                });


                addMarker(map, site.latlng.lng, site.latlng.lat);

                //add circle layer
                createCircle(map, site.latlng.lng, site.latlng.lat, radius, 64); 

                
                            //end add circle layer
                
                            } catch (error) {
                                console.error('Error fetching protected areas:', error);
                            } finally {
                                setIsLoading(false);
                            }
                        });
                    };
    
        loadMapData();
    
        // Cleanup on component unmount
        return () => map.remove();
    }, [site.latlng, setIsLoading]); // Include dependencies here if they can change
    
    
    return (
        <>
        {isLoading && <LoadingAnimation />}
         <div id="map" style={{ position: 'absolute', top: 0, bottom: 0, width: '100%' }} />
        <AlertWindow
            data={selectedBSA}
            isOpen={isAlertOpen}
            onClose={closeAlert}
        />
        </>
    );
};

export default BSAMap;

const AlertWindow = ({ data, isOpen, onClose }) => {
    console.log('data AlertWindow ===>> ', data);
    if (!isOpen) return null;
  
    return (
      <div className="alert-window">
        <h3>GID: {data.gid}</h3>
        <h3>Name: {data.name}</h3>
        <p>Orig. Name: {data.orig_name}</p>
        <p>Type: {data.desig_eng}</p>
        <p>Reported Area (km²): {data.rep_area.toFixed(3)}</p>
        <p>Status Year: {data.status_yr}</p>
        <button onClick={onClose}>Close</button>
      </div>
    );
  };
