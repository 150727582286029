import * as React from "react";
import mapboxgl, { LngLatBoundsLike } from "mapbox-gl";
import { mdiFullscreen, mdiFullscreenExit } from "@mdi/js";
import { apiGet, isEqual, truncate } from "@/utils";
import { AppContext } from "@/context";
import { setInteractive } from "./utils";
import { SatelliteStyleUri } from "./constants";
import MapZoomPicker from "./MapZoomPicker";
import "mapbox-gl/dist/mapbox-gl.css";
import { colors } from "@/utils/style";
import MapLegends from "./MapLegends";

interface IMapCircle {
  center: ILatLng;
  id: string;
  radius: number;
}

interface IStaticMapProps {
  bbox?: number[];
  center?: ILatLng;
  circles?: IMapCircle[];
  interactive?: boolean;
  layers: string[];
  markers?: {
    center: ILatLng;
    color: string;
    id: string;
  }[];
  onClick?: (e: ILatLng) => void;
  onDoubleClick?: (e: ILatLng) => void;
  onFullscreenChange?: (enabled: boolean) => void;
  onLayerLoad?: (id: string, layer: IKeyAreaLayer) => void;
  onPinClick?: (pin: IMapPin) => void;
  rasters: Record<string, IProjectRasterConfig>;
  roundedCorners?: boolean;
  pins?: IMapPin[];
  zoom: number;
  baseMapUri?: string;
  left?: boolean;
  right?: boolean;
  unsetZoom?: boolean;
  hideControls?: boolean;
  unsetZoomHandler?: () => void;
  protectedAreas?: IKeyArea[];
}

interface IStaticMapState {
  basemap: string;
  bbox?: number[];
  boundMapClick: (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => void;
  boundMapIdle: () => void;
  boundMapLoad: () => void;
  boundMapMouseOver: () => void;
  boundMapZoom: () => void;
  boundStyleDataChange: () => void;
  center: ILatLng;
  circles: Map<
    string,
    {
      circle: IMapCircle;
      datasource: mapboxgl.AnySourceData;
    }
  >;
  clickCount: number;
  fullscreen: boolean;
  interactive: boolean;
  initialized: boolean;
  layerIds: string[];
  map: mapboxgl.Map;
  mapId: string;
  markers: Record<string, mapboxgl.Marker>;
  pins: Map<
    string,
    {
      content: Node;
      label: Node;
      marker: mapboxgl.Marker;
      pin: IMapPin;
    }
  >;
  zoom?: number;
  hideControls?: boolean;
}

class PortalControl {
  _map: mapboxgl.Map;
  _container: HTMLElement;
  _corner: string;
  _id: string;

  constructor(id: string, corner: string) {
    this._id = id;
    this._corner = corner;
  }

  onAdd(map: mapboxgl.Map): HTMLElement {
    const c = document.createElement("div");

    c.id = this._id;
    this._map = map;
    this._container = c;

    return this._container;
  }

  onRemove(): void {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }
}

const pinCenterIsMapCenter = (
  pinCenter: ILatLng,
  mapCenter: ILatLng | undefined
) => {
  if (!mapCenter?.lat || !mapCenter?.lng || !pinCenter?.lng || !pinCenter?.lat)
    return;

  const pinLat = pinCenter.lat.toFixed(2);
  const pinLng = pinCenter.lng.toFixed(2);
  const mapLat = mapCenter.lat.toFixed(2);
  const mapLng = mapCenter.lng.toFixed(2);
  return mapLat === pinLat && mapLng === pinLng;
};

function createCircle(
  center: ILatLng,
  radiusInKm: number,
  points = 64
): mapboxgl.AnySourceData {
  const output = [];
  const dx = radiusInKm / (111.32 * Math.cos((center.lat * Math.PI) / 180));
  const dy = radiusInKm / 110.574;
  let theta, x, y;

  for (let i = 0; i < points; i += 1) {
    theta = (i / points) * (2 * Math.PI);
    x = dx * Math.cos(theta);
    y = dy * Math.sin(theta);

    output.push([center.lng + x, center.lat + y]);
  }

  output.push(output[0]);

  return {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {},
          geometry: {
            type: "Polygon",
            coordinates: [output],
          },
        },
      ],
    },
  };
}

export const KeyBiodiversityAreaColor = "red";
export const Natura2000AreaColor = "blue";
export const SpeciesColor = "#32A852";

const PinLabelMinZoomLevel = 6;
let formerCenterPinId: string;

function getLayerPaintStyle(id: string) {
  let color = SpeciesColor;
  let opacity = 0.66;

  if (/^N2k/.test(id)) {
    color = Natura2000AreaColor;
    opacity = 0.33;
  }

  return {
    "fill-color": color,
    "fill-opacity": opacity,
    "fill-outline-color": "transparent",
  };
}

const SpeciesCirclePaint: mapboxgl.CirclePaint = {
  "circle-radius": 4,
  "circle-color": "#6F3782",
  "circle-stroke-color": "white",
  "circle-stroke-width": 1,
  "circle-opacity": 0.75,
};

class ClickableMarker extends mapboxgl.Marker {
  _clickHandler: () => void;

  onClick(handler: () => void) {
    this._clickHandler = handler;
    return this;
  }

  _onMapClick(e: mapboxgl.MapMouseEvent) {
    const targetEl = e.originalEvent.target as HTMLElement;
    const el = (this as unknown as { _element: HTMLElement })._element;

    if (this._clickHandler && (targetEl === el || el.contains(targetEl))) {
      this._clickHandler();
    }
  }
}

export default class StaticMap extends React.Component<
  IStaticMapProps,
  IStaticMapState
> {
  static contextType = AppContext;

  context: React.ContextType<typeof AppContext>;

  constructor(props: IStaticMapProps) {
    super(props);

    this.state = {
      basemap: SatelliteStyleUri,
      bbox: props.bbox,
      boundMapClick: undefined,
      boundMapIdle: undefined,
      boundMapLoad: undefined,
      boundMapMouseOver: undefined,
      boundMapZoom: undefined,
      boundStyleDataChange: undefined,
      center: props.center,
      circles: new Map(),
      clickCount: 0,
      fullscreen: false,
      interactive: props.interactive || false,
      initialized: false,
      layerIds: [],
      map: null,
      mapId: "map-" + new Date().getTime(),
      markers: {},
      pins: new Map(),
      zoom: props.zoom,
      hideControls: props.hideControls,
      unsetZoom: props.unsetZoom,
      unsetZoomHandler: props.unsetZoomHandler,
    };
  }

  async componentDidMount() {
    const { state } = this.context;

    // TODO: wie im FE importieren django conf?
    mapboxgl.accessToken =
      state.config?.mapbox_public_api_token ||
      "pk.eyJ1IjoiYW5kcmUtaGlja21hbm4iLCJhIjoiY2xoNjR4enBkMDE3cjNqcGc0aG93ZzlueSJ9.JH3ClP3oIf2uvc4ZpFvjJQ";

    const map = new mapboxgl.Map({
      attributionControl: false,
      center: this.props.center,
      container: this.state.mapId,
      interactive: false,
      localFontFamily: "Roobert",
      style: this.state.basemap,
      // projection: 'globe', // Set the map projection to 'globe'
      zoom: this.props.zoom,
    });

    const newState = {
      ...this.state,
      map,
      boundMapClick: (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) =>
        this.onMapClick(e),
      boundMapIdle: () => this.onMapIdle(),
      boundMapLoad: () => this.onMapLoad(),
      boundMapZoom: () => this.onMapZoom(),
      boundMapMouseOver: () => this.onMapMouseOver(),
      boundStyleDataChange: () => this.onStyleDataChange(),
    };

    this.setState(newState);

    map.on("click", newState.boundMapClick);
    map.on("idle", newState.boundMapIdle);
    map.on("load", newState.boundMapLoad);
    map.on("zoomend", newState.boundMapZoom);
    map.on("mouseover", newState.boundMapMouseOver);
    map.on("styledata", newState.boundStyleDataChange);

    window.addEventListener("resize", newState.boundMapIdle);
  }

  onMapLoad(): void {
    const { bbox, center, interactive, map, zoom, hideControls } = this.state;

    if (!map) {
      return;
    }

    if (!hideControls) {
      map.addControl(
        new mapboxgl.AttributionControl({ compact: true }),
        "top-left"
      );
      map.addControl(
        new PortalControl("map-zoom-control", "bottom-right"),
        "bottom-right"
      );
    }

    setInteractive(map, interactive);

    this.setState({ ...this.state, initialized: true });

    if (bbox) {
      map.fitBounds(bbox as LngLatBoundsLike);
    } else if (center) {
      map.setCenter(center);
      map.setZoom(zoom);
    }

    this.forceUpdate();
  }

  onMapIdle(): void {
    this.state.map?.resize();
  }

  onMapClick(e: mapboxgl.MapMouseEvent & mapboxgl.EventData): void {
    if (!this.state.interactive) {
      this.setState({ ...this.state, interactive: true });
      setInteractive(this.state.map, true);
    }

    // Hold for double click

    window.setTimeout(() => {
      if (this.state.clickCount === 1) {
        this.props.onClick?.(e.lngLat);
      }

      this.setState({ ...this.state, clickCount: 0 });
    }, 300);

    if (this.state.clickCount === 1) {
      this.props.onDoubleClick?.(e.lngLat);
    }

    this.setState({ ...this.state, clickCount: this.state.clickCount + 1 });
  }

  onStyleDataChange(): void {
    this.componentDidUpdate();
  }

  onMapMouseOver(): void {
    if (!this.state.initialized || this.state.interactive) {
      return;
    }

    const wrapper = document.querySelector("#" + this.state.mapId);
    let help = wrapper?.querySelector(
      "#" + this.state.mapId + "-activation-help"
    ) as HTMLDivElement;

    if (!wrapper || help) {
      return;
    }

    help = document.createElement("div");

    help.id = this.state.mapId + "-activation-help";
    help.textContent = "Click on the map to activate it";
    help.style.pointerEvents = "none";
    help.style.fontSize = ".85rem";
    help.style.background = "rgba(255, 255, 255, .75)";
    help.style.borderRadius = ".5rem";
    help.style.padding = ".5rem 2rem";
    help.style.zIndex = "20";
    help.style.position = "absolute";
    help.style.bottom = ".25rem";
    help.style.left = "50%";
    help.style.transform = "translate(-50%, -50%)";

    wrapper.appendChild(help);
    window.setTimeout(() => help.parentElement?.removeChild(help), 1500);
  }

  onMapZoom(): void {
    const { map, pins } = this.state;

    if (!map) {
      return;
    }

    if (PinLabelMinZoomLevel <= map.getZoom()) {
      for (const pin of pins.values()) {
        if (pin.label && !pin.label.parentNode) {
          pin.content.appendChild(pin.label);
        }
      }
    } else {
      for (const pin of pins.values()) {
        if (pin.label && pin.label.parentNode) {
          pin.content.removeChild(pin.label);
        }
      }
    }
  }

  componentWillUnmount(): void {
    const {
      boundMapClick,
      boundMapIdle,
      boundMapLoad,
      boundMapMouseOver,
      boundMapZoom,
      map,
    } = this.state;

    if (map) {
      map.off("click", boundMapClick);
      map.off("idle", boundMapIdle);
      map.off("load", boundMapLoad);
      map.off("zoom", boundMapZoom);
      map.off("mouseover", boundMapMouseOver);

      window.removeEventListener("resize", boundMapIdle);
    }
  }

  getProjectSlug() {
    // TODO Remove this in the future for something that is from the context

    return document.location.pathname.split("/")[2];
  }

  recenterMap() {
    const { bbox, center, zoom, unsetZoom } = this.props;
    const map = this.state.map;

    if (map && bbox && !isEqual(bbox, this.state.bbox)) {
      this.setState({ ...this.state, bbox, center });
      map.fitBounds(bbox as LngLatBoundsLike);
    } else if (map && center && !isEqual(center, this.state.center)) {
      this.setState({ ...this.state, bbox, center, zoom });
      map.flyTo({ center, zoom });
    } else if (unsetZoom) {
      map.flyTo({ center, zoom });
      this.props.unsetZoomHandler();
    }
  }

  syncRasterLayers() {
    // Remove rasters that are not enabled
    const map = this.state.map;
    const token = this.context.state.config?.tileserver_api_token;
    const addArgs = (cmap: string, url: string) =>
      `${url}${url.indexOf("?") >= 0 ? "&" : "?"}${cmap ? "colormap=" + cmap : ""
      }&token=${token}`;
    const ids = Array.from(Object.keys(this.props.rasters || {}));
    const layers = map.getStyle().layers;
    let firstSymbolId: string = undefined;

    for (const lyr of layers) {
      if (lyr.type === "symbol") {
        firstSymbolId = lyr.id;
        break;
      }
    }

    layers.forEach((lyr) => {
      if (
        lyr.type === "raster" &&
        lyr.source !== "mapbox" &&
        lyr.source !== "mapbox://mapbox.satellite" &&
        !ids.includes(lyr.id)
      ) {
        map.removeLayer(lyr.id);
        map.removeSource(lyr.id);
      }
    });

    // Add missing rasters

    Object.values(this.props.rasters || {}).forEach((r) => {
      if (!map.getSource(r.id)) {
        if (r.url.indexOf("mapbox") === 0) {
          // Rasters served from mapbox
          map.addSource(r.id, { type: "raster", tileSize: 256, url: r.url });
          map.addLayer({ id: r.id, source: r.id, type: "raster" }, "water");
        } else {
          // Rasters served from our terracotta
          console.log("raster URL ->", r.url);
          console.log("raster tile size ->", r.tilesize);

          map.addSource(r.id, {
            type: "raster",
            tiles: [r.url],
            tileSize: r.tilesize || 256,
          });
          map.addLayer(
            { id: r.id, type: "raster", source: r.id },
            firstSymbolId
          );
          //, paint: { 'raster-opacity': 0.7 }
        }
      }
    });
  }
  syncProtectedAreas() {
    const { map } = this.state;

    if (!map) {
      return;
    }

    const polygonData = {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: {
              type: "Polygon",
              coordinates: this.props.protectedAreas.map(
                (area) => area.coordinates
              ),
            },
          },
        ],
      },
    };

    if (!map.getSource("polygon-protected-area")) {
      map.addSource(
        "polygon-protected-area",
        polygonData as mapboxgl.AnySourceData
      );
      map.addLayer({
        id: "polygon-protected-area",
        type: "fill",
        source: "polygon-protected-area",
        paint: {
          "fill-color": "red",
          "fill-opacity": 0.7,
        },
      });
    }
  }

  syncLayers() {
    const { layerIds, map } = this.state;
    const team = this.context.state.config?.team;
    let changed = false;

    if (!map) {
      return;
    }

    // Remove layers not present in context anymore

    for (let i = layerIds.length - 1; i >= 0; i -= 1) {
      const id = layerIds[i];

      if (!this.props.layers.includes(id) && map.getSource(id)) {
        changed = true;

        map.removeLayer(id);
        map.removeSource(id);
        layerIds.splice(i, 1);
      }
    }

    // Add new layers

    for (let i = 0; i < this.props.layers.length; i += 1) {
      const id = this.props.layers[i];

      if (layerIds.includes(id)) {
        continue;
      }

      layerIds.push(id);

      changed = true;

      apiGet<IKeyAreaLayer>(
        team.slug,
        `projects/${this.getProjectSlug()}/maps/${id}`
      ).then((reply) => {
        if (!reply?.ok) {
          return;
        }

        const { geometry, properties } = reply.data;

        if (!map.getSource(id)) {
          map.addSource(id, geometry);
        }

        if (properties.kind === "points") {
          map.addLayer({
            id: id,
            type: "circle",
            source: id,
            paint: SpeciesCirclePaint,
          });
        } else {
          map.addLayer({
            id: id,
            type: "fill",
            source: id,
            paint: getLayerPaintStyle(id),
          });
        }

        for (const id of Object.keys(this.state.markers)) {
          if (map.getLayer(id + "-inner-circle")) {
            map.moveLayer(id + "-inner-circle");
          }

          if (map.getLayer(id + "-outer-circle")) {
            map.moveLayer(id + "-outer-circle");
          }
        }

        this.props.onLayerLoad?.(id, reply.data);
      });
    }

    if (changed) {
      this.setState({ ...this.state, layerIds });
    }
  }

  syncMarkers() {
    const { map, markers } = this.state;

    if (!map) {
      return;
    }

    // Remove obsolete markers

    const ids = this.props.markers?.map((m) => m.id) || [];
    let changed = false;

    for (const id of Object.keys(markers)) {
      if (!ids.includes(id)) {
        changed = true;
        markers[id].remove();
        delete markers[id];
      }
    }

    for (const m of this.props.markers || []) {
      if (!markers[m.id]) {
        changed = true;
        markers[m.id] = new ClickableMarker({ color: m.color || "black" })
          .setLngLat([m.center.lng, m.center.lat])
          .addTo(map);
      }
    }

    if (changed) {
      this.setState({ ...this.state, markers });
    }
  }

  syncPins() {
    const { map, pins } = this.state;

    if (!map) {
      return;
    }

    const zoom = map.getZoom();
    const showPinLabels = zoom >= PinLabelMinZoomLevel;
    const pinIds = this.props.pins?.map((p) => p.id) || [];

    for (const id of pins.keys()) {
      if (!pinIds.includes(id)) {
        const pin = pins.get(id);

        pin.marker.remove();
        pins.delete(id);
      }
    }

    let newCenterPinId: string;
    for (const pin of this.props.pins || []) {
      const pinIsNewCenter = pinCenterIsMapCenter(
        pin.center,
        this.props.center
      );
      const pinIsFormerCenterPin = formerCenterPinId === pin.id;
      if (pinIsNewCenter) {
        // Add a new standard pin
        const marker = new mapboxgl.Marker({
          color: pin.isPriorityLocation ? colors.purple : colors.brightBlue,
        })
          .setLngLat(pin.center)
          .on("click", () => this.props.onPinClick?.(pin))
          .addTo(map);

        const formerCenterPin = document.getElementById("centerPin");

        if (formerCenterPin) {
          formerCenterPin.remove();
        }
        marker.getElement().id = "centerPin";
        pins.set(pin.id, { pin, content: undefined, label: null, marker });
        newCenterPinId = pin.id;
      } else if (!pins.get(pin.id) || pinIsFormerCenterPin) {
        const wrapper = document.createElement("div");
        const content = document.createElement("div");
        const circle = document.createElement("span");
        const innerCircle = document.createElement("span");
        const label = document.createElement("span");
        const marker = new mapboxgl.Marker(wrapper)
          .setLngLat(pin.center)
          .addTo(map);

        wrapper.classList.add("s-site-pin");

        wrapper.appendChild(content);

        content.classList.add("s-site-pin-content");

        label.textContent = truncate(pin.text, 30);
        label.classList.add("s-site-pin-label");

        if (showPinLabels) {
          content.appendChild(label);
        }

        content.addEventListener("click", (e: MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();

          this.props.onPinClick?.(pin);
        });

        circle.classList.add("s-site-pin-circle");
        innerCircle.classList.add("s-site-pin-circle-inner");
        pin.isPriorityLocation && innerCircle.classList.add("purple-circle");
        content.appendChild(circle);
        content.appendChild(innerCircle);

        pins.set(pin.id, { pin, content, label, marker });
      }
    }
    formerCenterPinId = newCenterPinId;
  }

  syncCircles() {
    const { circles, map } = this.state;

    if (!map) {
      return;
    }

    const circleIds = this.props.circles?.map((p) => p.id) || [];

    for (const id of circles.keys()) {
      if (!circleIds.includes(id)) {
        if (map.getLayer(id)) {
          map.removeLayer(id);
          map.removeSource(id);
        }

        circles.delete(id);
      }
    }

    for (const circle of this.props.circles || []) {
      if (!circles.get(circle.id)) {
        const datasource = createCircle(circle.center, circle.radius / 1000);

        if (!map.getSource(circle.id)) {
          map.addSource(circle.id, datasource as mapboxgl.AnySourceData);
          map.addLayer({
            id: circle.id,
            type: "fill",
            source: circle.id,
            paint: {
              "fill-outline-color": "red",
              "fill-color": "transparent",
              "fill-opacity": 1,
            },
          });

          circles.set(circle.id, { circle, datasource });
        }
      }
    }
  }

  componentDidUpdate(prevProps?: IStaticMapProps) {
    const { initialized, map } = this.state;

    if (prevProps && prevProps.baseMapUri !== this.props.baseMapUri) {
      setInteractive(this.state.map, true);

      this.setState({
        ...this.state,
        basemap: this.props.baseMapUri,
        circles: new Map(),
        interactive: true,
        layerIds: [],
        markers: {},
      });
      this.state.map.setStyle(this.props.baseMapUri);
    }

    if (!map || !initialized) {
      return;
    }

    this.syncRasterLayers();
    this.syncLayers();
    this.syncMarkers();
    this.syncCircles();
    this.syncPins();
    this.props.protectedAreas && this.syncProtectedAreas();
    this.recenterMap();
  }

  render() {
    const toggleFullscreen = (
      e: React.MouseEvent<HTMLOrSVGElement>,
      enabled: boolean
    ) => {
      e.stopPropagation();
      e.preventDefault();

      const isInteractive = this.state.interactive;

      this.setState({ ...this.state, fullscreen: enabled, interactive: true });
      this.props.onFullscreenChange?.(enabled);

      window.setTimeout(() => {
        this.state.map.resize();
        this.recenterMap();

        if (!isInteractive) {
          setInteractive(this.state.map, true);
        }
      }, 150);
    };

    const toggleDataLayerModal = () => {
      const { map } = this.state;
      this.context.dispatch({ type: "toggleDataLayerModal" });
      /////
      if (map) {
        const isSatelliteViewEnabled = !this.context.state.isSatelliteViewEnabled;

        if (isSatelliteViewEnabled) {
          map.setStyle("mapbox://styles/mapbox/satellite-streets-v11");
          map.setProjection('globe'); // Set the projection to globe
        } else {
          map.setStyle(SatelliteStyleUri); // Use your original style URI
        }

        this.setState({ ...this.state, fullscreen: false, interactive: true });
        this.props.onFullscreenChange?.(false);
      }
      ///////
      //No need to add the country boundaries because
      //the satellite-streets-v11 already include it
      // Check if the source already exists
      // if (!map.getSource('countries')) {
      //   // If it doesn't exist, add it
      //   map.addSource('countries', {
      //     'type': 'vector',
      //     'url': 'mapbox://mapbox.country-boundaries-v1'
      //   });
      // }

      // // Check if the layer already exists
      // if (!map.getLayer('country-boundaries')) {
      //   // If it doesn't exist, add it
      //   map.addLayer({
      //     'id': 'country-boundaries',
      //     'type': 'line',
      //     'source': 'countries',
      //     'source-layer': 'country_boundaries',
      //     'paint': {
      //       'line-color': 'gree', // Green color for the country borders
      //       'line-width': 1
      //     }
      //   });
      // }
    };


    return (
      <>
        <div style={{
          position: 'absolute',
          left: '50%',
          bottom: 30,
          width: '450px',
          zIndex: 9,

        }}>
          <div style={{
            position: 'relative',
            left: '-50%',
            height: '100%',
            width: '100%',
          }}>

            <MapLegends rasters={this.props.rasters} />
          </div>
        </div>
        <div
          id={this.state.mapId}
          className={
            this.props.roundedCorners === undefined || this.props.roundedCorners
              ? "s-map-rounded-corners"
              : undefined
          }
          style={{
            bottom: 0,
            left: this.props.right ? "40%" : 0,
            position: "absolute",
            right: this.props.left ? "40%" : 0,
            top: 0,
          }}
        />

        {this.state.map && (
          <>
            {this.props.onFullscreenChange && (
              <div className="mapboxgl-ctrl">
                <div className="s-map-layer-controls-container">
                  <div
                    className="s-map-button"
                    onClick={(e) => toggleFullscreen(e, !this.state.fullscreen)}
                    style={{ height: "40px" }}
                  >
                    <svg viewBox="0 0 24 24" width={24} height={24}>
                      <path
                        d={
                          this.state.fullscreen
                            ? mdiFullscreenExit
                            : mdiFullscreen
                        }
                        fill="black"
                        strokeWidth="0.5"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            )}

            <MapZoomPicker
              onZoomClick={(what) => {
                const map = this.state.map;

                this.setState({ ...this.state, interactive: true });
                setInteractive(this.state.map, true);
                map.flyTo({ zoom: map.getZoom() + (what === "plus" ? 1 : -1) });
              }}
              toggleDataLayerModal={toggleDataLayerModal}
              targetElementId="map-zoom-control"
            />
          </>
        )}
      </>
    );
  }
}
