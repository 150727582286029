import * as React from 'react';
import { AppContext, useProject, useSites } from '@/context';
import { expandBBox, externalHttpGet } from '@/utils';
import { MapboxApiEndpoint, StaticMap } from '@/lib/map';
import './index.scss';
import { SleapContext } from "@/context/SleapContext";

interface IWrapperProps {
    left?: boolean
    right?: boolean,
}

export default function StaticMapWrapper(props: IWrapperProps): JSX.Element {
    const { state } = React.useContext(AppContext);
    const project = useProject();
    const [pointOfInterest, setPointOfInterest] = React.useState<ILatLng & { siteId?: number }>(undefined);
    const [mapBBox, setMapBBox] = React.useState<number[]>(expandBBox(project?.minimap.bbox));
    const [mapCenter, setMapCenter] = React.useState<ILatLng>(undefined);
    const [mapZoom, setMapZoom] = React.useState<number>(2);
    const sites = useSites();
    const [pins, setPins] = React.useState<IMapPin[]>([]);

    const [selectedCenter, setSelectedCenter] = React.useState<ILatLng | undefined>(undefined);
    const [unsetZoom, setUnsetZoom] = React.useState(false);
    const { sleapState, sleapDispatch } = React.useContext(SleapContext);
    const baseMap = state.projects?.exploreMapBaseMap;
    const mapRasters = state.projects?.exploreMapRasters;
    const layers = React.useMemo(() => [], []);
    React.useEffect(() => {

        sleapState?.bbox && setMapBBox(sleapState.bbox);
        sleapState?.zoom && setMapZoom(sleapState?.zoom);
        sleapState?.mapCenter && setMapCenter(sleapState.mapCenter);
    }, [sleapState]);

    const setSiteLocation = React.useCallback((point: ILatLng) => {
        console.log('setSiteLocation', point);

        if (state.config?.user) setSelectedCenter({ lat: point.lat, lng: point.lng });
    }, []);

    const fetchAddress = (center: ILatLng) => {

        const { lat, lng } = center;
        const url = `${MapboxApiEndpoint}/${lng},${lat}.json`;
        const params = { access_token: state.config.mapbox_public_api_token };

        externalHttpGet<IMapboxQueryResult>(url, params).then(reply => {

            let country, street, zipCode, city = '';
            const precisionLevel = reply.features[0].place_type[0];
            const resultAddressData = reply.features[0].place_name.split(', ');
            if (precisionLevel === 'address' && resultAddressData.length > 2) {
                street = resultAddressData[0];
                [zipCode, city] = resultAddressData[1].split(' ');
            }
            country = resultAddressData[resultAddressData.length - 1];

            sleapDispatch({
                selectedCenter: {
                    address: {
                        street_address: street,
                        zip_code: zipCode,
                        city: city,
                        country: country,
                    },
                    latlng: center,
                },
            });
        });
    };

    React.useEffect(() => {

        if (selectedCenter) {

            sleapDispatch({ selectedCenter: selectedCenter });
            setPointOfInterest(selectedCenter);
            fetchAddress(selectedCenter);
        }
    }, [selectedCenter]);

    React.useEffect(() => {

        sleapDispatch({ selectedSiteId: pointOfInterest?.siteId });
    }, [pointOfInterest]);

    React.useEffect(() => {

        if (sleapState?.unsetZoom) {

            setMapZoom(2);
            setUnsetZoom(true);
        }
    }, [sleapState?.unsetZoom]);


    React.useEffect(() => {
        console.log('sites length ====xxx>>>> ', sites.length);
        //console.log('whole sites ====xxx>>>> ', sites);
        const filteredSites = sleapState?.filteredSites;
        if (filteredSites && filteredSites.length < 50) {

            setPins(filteredSites.map(s => ({ center: s.latlng, id: 'site-' + s.id, siteId: s.id, text: s.name ?? s.site_id, isPriorityLocation: s.is_priority_site })));
        } else if (sites && sites.length < 50) {
            setPins(sites.map(s => ({ center: s.latlng, id: 'site-' + s.id, siteId: s.id, text: s.name ?? s.site_id, isPriorityLocation: s.is_priority_site })));
        }
    }, [sleapState?.filteredSites, sites]);

    React.useEffect(() => {

        if (sleapState?.targetSiteId) {

            const targetSite = sites.find(s => s.id === sleapState.targetSiteId);
            targetSite && setMapCenter(targetSite.latlng);

        }
    }, [sleapState?.targetSiteId]);

    const unsetZoomHandler = React.useCallback(() => {

        sleapDispatch({ unsetZoom: false });
        setUnsetZoom(false);
    }, []);

    const handlePinClick = React.useCallback((pin: IMapPin) => {

        setPointOfInterest({ ...pin.center, siteId: (pin as unknown as { siteId: number }).siteId });
    }, []);

    return (
        <StaticMap
            bbox={mapBBox}
            center={mapCenter}
            interactive={true}
            layers={layers}
            onClick={setSiteLocation}
            onPinClick={handlePinClick}
            pins={pins}
            rasters={mapRasters}
            roundedCorners={false}
            zoom={mapZoom}
            baseMapUri={baseMap}
            left={props.left}
            right={props.right}
            hideControls={true}
            unsetZoom={unsetZoom}
            unsetZoomHandler={unsetZoomHandler}
        />
    );
}