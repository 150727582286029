import { useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';

const useFetchImpacts = (economicActivitiesIds:string) => {
  const [totalUniqueImpacts, setTotalUniqueImpacts] = useState(null);
  const [totalUniqueImpactsWithRating, setTotalUniqueImpactsWithRating] = useState(null);
  const [restructuredImpactsGeoServer, setRestructuredImpactsGeoServer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();
const header = {
    headers: {
        'Authorization': `Bearer ${encryptedToken}`
    }
};

   useEffect(() => {
    const fetchImpacts = async () => {
      console.log('fetching impacts ===>>> ', economicActivitiesIds);
      const url = `${process.env.KUYUA_APP_BACKEND_URL}/impacts?economic_activities_ids=${economicActivitiesIds}`;
      try {
        const response = await fetch(url, header);
        const data = await response.json();
        if (data.impacts && data.impacts.totalUnique) {
          console.log('hey from Chook data.impacts.totalUnique ===>>>> ', data.impacts.totalUnique);
          setTotalUniqueImpacts(data.impacts.totalUnique);
          setTotalUniqueImpactsWithRating(data.impacts.totalUniqueWithRating);
          setRestructuredImpactsGeoServer(data.impacts.restructuredItems);
          //save into local storage
          localStorage.setItem('totalUniqueImpacts', JSON.stringify(data.impacts.totalUnique));
          localStorage.setItem('totalUniqueImpactsWithRating', JSON.stringify(data.impacts.totalUniqueWithRating));
          localStorage.setItem('restructuredImpactsGeoServer', JSON.stringify(data.impacts.restructuredItems));
        } else {
          console.log('No impacts found for this site');
        //   return({ impacts: 'N/A' })
        }
      } catch (error) {
        console.error('Error fetching impacts:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (economicActivitiesIds.length > 0) {
      fetchImpacts();
    }
  }, [economicActivitiesIds]);

  return {
    totalUniqueImpacts,
    totalUniqueImpactsWithRating,
    restructuredImpactsGeoServer,
    loading,
    error,
  };
};

export default useFetchImpacts;