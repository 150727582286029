import * as React from "react";
import { AppContext, ToastAdded, useProject, useTeam } from "@/context";
import { apiGet, apiPost, formatDate, getHomeUrl, t, unique } from "@/utils";
import { useSleapStep } from "@/utils/customHooks";
import { SortOrder } from "@/utils/sort";
import { Icon, LoadingAnimation } from "@/lib";
import { mdiChevronDown } from "@mdi/js";
import Pagination from "@/lib/Pagination/Pagination";
import { colors, style, StyleSheet } from "@/utils/style";
import { LocationSearch } from "@/project/sleap/Locate/LocationSearch";
import Checkbox from "@/utils/Checkbox";
import { fetchScopes } from "@/rest/apiHelper";
import Dropdown from "@/utils/Dropdown";
import { NavLink, useLocation } from "react-router-dom";
import { accumulatePriorityScore } from "@/utils/calculatePriorityScore";
import { ExportButton } from "../sleap/Assess/ExportButton";
import CryptoJS from "crypto-js";
import { downloadSite } from "@/utils/downloadSite";
import { CSVLink } from "react-csv";
import { sbm3 } from "@/utils/sbm3";

const maxSitesOnPage = 10;
const sortType = "priority_score";
export const taskProcess = (site: INewSite): string => {
  if (site.number_of_tasks) {
    return `${(site.number_of_completed_tasks / site.number_of_tasks).toFixed(
      0
    )}%`;
  } else {
    return "0%";
  }
};

type IAllSitesTableProps = {
  activeDetailTab?: null | "overview" | "species" | "actions";
};
let oldDbSites = [];
type selectionChoiceMode = "all_page" | "all" | "none";

export const AllSitesTable: React.FC<IAllSitesTableProps> = ({
  activeDetailTab,
}) => {
  const { dispatch, state } = React.useContext(AppContext);
  const [searchText, setSearchText] = React.useState<string>("");
  const [countries, setCountries] = React.useState<string[]>(["All"]);
  const [sortOrder, setSortOrder] = React.useState<SortOrder>("desc");
  const [page, setPage] = React.useState<number>(1);
  const [sites, setSites] = React.useState<INewSite[]>(undefined);
  const [sitesCount, setSitesCount] = React.useState<number>(-1);
  const [selectedSiteTypeIds, setSelectedSiteTypeIds] = React.useState<
    number[]
  >([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const project = useProject();
  const team = useTeam();
  const location = useLocation();
  const tabId = location?.state === "P" ? "actions" : "overview";
  const [widthOffsets, setWidthOffsets] = React.useState<{
    last: number;
    secondToLast: number;
  }>({ last: 0, secondToLast: 0 });
  const [, setSleapStep] = useSleapStep();
  const encryptedToken = CryptoJS.AES.encrypt(
    process.env.API_KEY,
    process.env.SECRET_KEY
  ).toString();
  const header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${encryptedToken}`,
    },
  };
  const getSites = () => {
    setLoading(true);
    //Initalize URL for getting sites from kuyuaappdb
    let NewDbUrl = `${process.env.KUYUA_APP_BACKEND_URL}/sites?page=${page}&company_id=${team.id}`;
    let NewDbUrlQueries = [];
    /////
    const queries = [];
    if (!countries.includes("All")) {
      queries.push(`countries[]=${countries.join("&countries[]=")}`);
      NewDbUrlQueries.push("country=" + countries);
    }
    if (searchText) {
      queries.push("filter=" + searchText);
      NewDbUrlQueries.push("search_all=" + searchText);
    }
    if (sortType) {
      queries.push("order_by=" + sortType);
      queries.push("order_direction=" + sortOrder);
      NewDbUrlQueries.push("sortBy=" + sortType);
      NewDbUrlQueries.push("sortOrder=" + sortOrder);
    }
    if (selectedSiteTypeIds.length) {
      queries.push(
        `site_type_ids[]=${selectedSiteTypeIds.join("&site_type_ids[]=")}`
      );
      NewDbUrlQueries.push("site_type_id=" + selectedSiteTypeIds);
    }
    const url = `projects/${project.slug}/reports/${project.latest_report_id}/site/page/${page}`;
    apiGet<{
      count: number;
      items: INewSite[];
      pages: number;
      current_page: number;
    }>(team.slug, url.concat(queries.length ? "?" + queries.join("&") : ""))
      .then((reply) => {
        if (reply.ok) {
          setSitesCount(reply.data.count);

          //console.log("sitesWithCalculatedScore ===>>> ", reply.data.items);
          oldDbSites = reply.data.items;
        } else {
          dispatch({
            type: ToastAdded,
            toast: { kind: "error", text: t("ui.failed_to_load_sites") },
          });
        }
      })
      .finally(async () => {
        try {
          NewDbUrl = NewDbUrl + "&" + NewDbUrlQueries.join("&");
          console.log(NewDbUrl);
          const response = await fetch(NewDbUrl, header);
          const data = await response.json();
          setSites(data.sites); //mappedSites
          console.log("mapped sites ===>>> ", data.sites);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching sites from kuyuaappdb:", error);
          dispatch({
            type: ToastAdded,
            toast: { kind: "error", text: t("ui.failed_to_load_sites") },
          });
        }
      });
    //////////////////////////////////
  };

  React.useEffect(() => {
    selectedSiteTypeIds.length && getSites();
  }, [page, sortOrder, sortType]);

  React.useEffect(() => {
    if (page !== 1) {
      setPage(1);
    } else {
      selectedSiteTypeIds.length && getSites();
    }
  }, [countries, searchText, selectedSiteTypeIds]);

  const handleSetCountry = (selection: string[]): void => {
    setCountries(selection);
  };

  const assessmentStatusColor = (status: string): string => {
    let color: string;
    switch (status) {
      case "running":
        color = colors.yellow;
        break;
      case "complete":
        color = colors.green;
        break;
      case "queued":
        color = colors.purple;
        break;
      default:
        color = colors.red;
    }
    return color;
  };

  const generateHoverTextString = (site: string) => {
    switch (site) {
      case "running":
        return "Assessment in process";
      case "complete":
        return `Assessment completed`;
      case "queued":
        return "Assessment queued";
      default:
        return "Assessment not started";
    }
  };

  const isSuperUser = state.config.user.is_superuser;

  const handleSiteType = (site_type_id: string) => {
    switch (site_type_id) {
      case "1":
        return "own";
      case "2":
        return "upstream";
      case "3":
        return "downstream";
      default:
        break;
    }
  };
  // Assign the side navigator tab
  React.useEffect(() => {
    //console.log("Location state ===>>", location?.state);
    if (location?.state === "P") {
      setSleapStep("P");
    } else {
      setSleapStep("A");
    }
  }, [sites]);

  React.useEffect(() => {
    const table = document.getElementById("table");
    const headerRow = table?.querySelector("thead tr");

    const secondToLast = headerRow?.cells[headerRow.cells.length - 2];
    const last = headerRow?.cells[headerRow.cells.length - 1];

    setWidthOffsets({
      secondToLast: secondToLast?.offsetWidth,
      last: last.offsetWidth,
    });
  }, [sites]);

  const handleSortByPriorityScore = () => {
    setSortOrder((prev) => (prev === "asc" ? "desc" : "asc"));
  };

  async function updateAssessmentsStatusNewBackend() {
    console.log("team.id ===>>> ", team.id);
    // const userConfirmed = window.confirm(
    //   "This will update ALL site assessments status on the new backend, Are you sure that all site assessments on the old backend (admin panel) are completed?"
    // );

    // if (!userConfirmed) {
    //   console.log("User cancelled the update.");
    //   return;
    // }

    try {
      const response = await fetch(
        `${process.env.KUYUA_APP_BACKEND_URL}/sites`,
        {
          method: "PATCH",
          ...header,
          body: JSON.stringify({
            company_id: team.id,
            updates: {
              site_asssessment_status_old: "complete",
            },
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Site assessment status updated successfully:", data);
        dispatch({
          type: ToastAdded,
          toast: {
            kind: "success",
            text: t("Site assessments status updated successfully"),
          },
        });
        return data;
      } else {
        const errorData = await response.json();
        console.error("Failed to update site assessment status:", errorData);
        return null;
      }
    } catch (error) {
      console.error("Error updating site assessment status:", error);
      return null;
    }
  }

  const DownloadSiteButton = ({ site }: { site: ISite }) => {
    const [csvData, setCsvData] = React.useState(null);
    const [isExporting, setIsExporting] = React.useState(false);
    const [error, setError] = React.useState(false);
    const handleDownload = async () => {
      setIsExporting(true);
      const data = await downloadSite(site, project, team);
      setIsExporting(false);
      if (!data) {
        setError(true);
        return;
      }

      setCsvData(data);
    };

    const date = new Date();

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear().toString().substring(-2);

    const formattedDate = `${day}${month}${year}`;

    const filename = `${site.site_name || " "}_${formattedDate}.csv`;

    return (
      <button
        onClick={handleDownload}
        style={{
          ...(error && {
            color: "white",
            backgroundColor: "#a62323",
          }),
          ...style.actionButton,
          ...styles.detailButton,
          ...styles.updateButton,
          justifyContent: "center",
          alignItems: "center",
          width: "150px",
          height: "30px",
          fontSize: "12px",
        }}
        disabled={isExporting}
      >
        {csvData ? (
          <CSVLink
            style={{
              color: "black",
              border: "black 1 solid",
            }}
            data={csvData}
            filename={filename}
          >
            Download Site
          </CSVLink>
        ) : isExporting ? (
          "Exporting ..."
        ) : error ? (
          "Failed"
        ) : (
          "Export Site"
        )}
      </button>
    );
  };

  const DownloadSBM3Button = ({ site }: { site: ISite }) => {
    const [csvData, setCsvData] = React.useState(null);
    const [isExporting, setIsExporting] = React.useState(false);
    const [error, setError] = React.useState(false);
    const handleDownload = async () => {
      setIsExporting(true);
      const data = await sbm3(site, project, team);
      setIsExporting(false);
      if (!data) {
        setError(true);
        return;
      }

      setCsvData(data);
    };

    const date = new Date();

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear().toString().substring(-2);

    const formattedDate = `${day}${month}${year}`;

    const filename = `${site.site_name || " "}_sbm3.csv`;

    return (
      <button
        onClick={handleDownload}
        style={{
          ...(error && {
            color: "white",
            backgroundColor: "#a62323",
          }),
          ...style.actionButton,
          ...styles.detailButton,
          ...styles.updateButton,
          justifyContent: "center",
          alignItems: "center",
          width: "150px",
          height: "30px",
          fontSize: "12px",
        }}
        disabled={isExporting}
      >
        {csvData ? (
          <CSVLink
            style={{
              color: "black",
              border: "black 1 solid",
            }}
            data={csvData}
            filename={filename}
            color="red"
          >
            Download SBM3
          </CSVLink>
        ) : isExporting ? (
          "Exporting ..."
        ) : error ? (
          "Failed"
        ) : (
          "SBM3"
        )}
      </button>
    );
  };

  const DownloadOptions = ({ site }: { site: ISite }) => {
    const [isOpen, setIsOpen] = React.useState(false);

    // Use a ref to store the current button element
    const buttonRef = React.useRef(null);

    // Add an effect to handle clicks outside the dropdown
    React.useEffect(() => {
      const handleClickOutside = (event) => {
        if (buttonRef.current && !buttonRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    return (
      <div style={{ position: 'relative' }} ref={buttonRef}>
        <button
          onClick={() => setIsOpen(!isOpen)}
          style={{
            width: "100px",
            height: "30px",
            fontSize: "12px",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0 10px',
            backgroundColor: 'transparent',
            borderRadius: '40px',
            border: `1px solid ${colors.brightBlue}`,
            color: 'white'
          }}
        >
          Download <Icon path={mdiChevronDown} size={0.8} />
        </button>
        {isOpen && (
          <div style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            backgroundColor: colors.mediumBlue,
            border: `1px solid ${colors.darkBlue}`,
            borderRadius: '4px',
            zIndex: 10,
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
            padding: 10
          }}>
            <DownloadSBM3Button site={site} />
            <DownloadSiteButton site={site} />
          </div>
        )}
      </div>
    );
  };

  return (
    <div style={{ flex: 1 }}>
      <TableSearchBar
        countryHandler={handleSetCountry}
        siteTypeHandler={setSelectedSiteTypeIds}
        searchHandler={setSearchText}
        sleapTab={tabId}
      />

      <div style={{ padding: 10 }}>
        <table className="sites-table" id={"table"}>
          <thead>
            <tr
              style={{
                textTransform: "uppercase",
              }}
            >
              <td
                style={{
                  textTransform: "uppercase",
                  display: "flex",
                  flexDirection: "row",
                  width: "20%",
                  whiteSpace: "nowrap",
                }}
              >
                <span onClick={() => handleSortByPriorityScore()}>
                  {t("ui.priority_score")}
                </span>
              </td>
              <td>{t("ui.name")}</td>
              <td>{t("ui.address")}</td>
              <td>{t("ui.country")}</td>
              <td>{t("ui.site_type")}</td>
              <td>{t("ui.radius")}</td>
              <td>{t("ui.download")}</td>
            </tr>
          </thead>
          <tbody>
            {sites &&
              sites.map((site) => (
                <tr key={site.site_id}>
                  <td>
                    {site.priority_score != -1 ? (
                      Number(site.priority_score).toFixed()
                    ) : (
                      <span className="spinner"></span>
                    )}
                  </td>
                  <td
                    onClick={() => {
                      localStorage.setItem(
                        "pscore",
                        Number(site.priority_score).toFixed(0)
                      );
                      const NoOfEA = JSON.stringify(site.economic_activities);
                      console.log(
                        "eco per site ---->",
                        site.economic_activities
                      );
                      localStorage.setItem("newEconomicActivities", NoOfEA);
                      localStorage.setItem("siteRadius", site.radius);
                    }}
                  >
                    <div className="s-flex-row s-flex-row--space-between">
                      <span>
                        {site.assessment_status === "complete" ? (
                          <NavLink
                            to={getHomeUrl(team) + `/site/${site.id}/${tabId}`}
                            state={Number(site.priority_score).toFixed()}
                          >
                            {site.site_name}
                          </NavLink>
                        ) : (
                          site.site_name
                        )}
                      </span>
                    </div>
                  </td>
                  <td>
                    {!site.street_address || !site.city || !site.zip_code
                      ? "-"
                      : site.street_address +
                      "," +
                      site.city +
                      "," +
                      site.zip_code}
                  </td>
                  <td>{site.country_code}</td>
                  <td>{handleSiteType(String(site.site_type_id))}</td>
                  <td>{site.radius / 1000}</td>
                  <td>
                    <DownloadOptions site={site as unknown as ISite} />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {loading && (
          <div className="s-loading-overlay">
            <LoadingAnimation />
          </div>
        )}
      </div>

      {isSuperUser && (
        <div
          style={{
            ...style.flexRow,
            ...styles.wrapper,
            marginTop: 10,
            padding: 10,
            justifyContent: "flex-end",
            gap: "unset",
          }}
        >
          <button
            style={{
              ...style.actionButton,
              ...styles.detailButton,
              ...styles.updateButton,
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => updateAssessmentsStatusNewBackend()}
          >
            update assessments
          </button>
        </div>
      )}

      {sitesCount > maxSitesOnPage && (
        <Pagination
          changePage={setPage}
          currentPage={page}
          lastPage={Math.ceil(sitesCount / maxSitesOnPage)}
        />
      )}
    </div>
  );
};

const TableSearchBar: React.FC<TableSearchBarProps> = ({
  countryHandler,
  siteTypeHandler,
  searchHandler,
  sleapTab,
}) => {
  const [scopeOptions, setScopeOptions] = React.useState<iScope[]>([]);
  const [selectedScopeIds, setSelectedScopeIds] = React.useState<number[]>([]);
  const [selectedCountryIds, setSelectedCountryIds] = React.useState<number[]>(
    []
  );
  const project = useProject();
  const team = useTeam();
  const url = `/api/v1/teams/${team.slug}/projects/${project.slug}/reports/${project.latest_report_id}/sites/assessments/2`;
  const countries = React.useMemo(() => {
    return [{ id: -1, label: t("ui.all") }].concat(
      unique(project.countries)
        .filter((country) => country)
        .sort()
        .map((id, i) => ({ id: i, label: id }))
    );
  }, [project.countries]);

  React.useEffect(() => {
    fetchScopes().then((reply) => {
      setScopeOptions(reply);
      setSelectedScopeIds(reply.map((scope) => scope.id));
    });
  }, []);

  const handleCheck = (id: number, check: boolean) => {
    if (check) {
      setSelectedScopeIds((prev) => [...prev, id]);
    } else {
      setSelectedScopeIds(selectedScopeIds.filter((scopeId) => scopeId !== id));
    }
  };

  const handleCountrySelect = (ids: number[]) => {
    const oldSelect = selectedCountryIds;
    const newSelect = ids.filter((newId) => !oldSelect.includes(newId));

    if (ids.length === 0 || newSelect.includes(-1)) {
      countryHandler(["All"]);
      setSelectedCountryIds([]);
    } else {
      const selectedCountries = countries
        .filter((country) => ids.includes(country.id))
        .map((country) => country.label);
      countryHandler(selectedCountries);
      setSelectedCountryIds(ids);
    }
  };

  React.useEffect(() => {
    siteTypeHandler(selectedScopeIds);
  }, [selectedScopeIds]);

  return (
    <div style={{ ...style.flexRow, ...styles.wrapper, padding: "10px 15px" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            ...style.centerFlex,
            flexDirection: "column",
            fontWeight: "bold",
          }}
        >
          <span style={{ fontSize: 12, textTransform: "uppercase" }}>
            {sleapTab === "overview" ? "Step 4" : "Step 5"}
          </span>
          <span style={{ fontSize: 25, lineHeight: 1 }}>
            {sleapTab === "overview" ? "A" : "P"}
          </span>
        </div>
        <span
          style={{ marginLeft: 30, fontSize: 25, color: colors.brightBlue }}
        >
          {sleapTab === "overview" ? "ASSESS" : "PREPARE"}
        </span>
      </div>

      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <div style={{ marginRight: 20 }}>
          <LocationSearch inputHandler={searchHandler} />
        </div>
        <div style={{ marginRight: 20 }}>
          <Dropdown
            placeholder={"country"}
            dropdownStyle={{
              padding: "5px 10px",
              marginBottom: "unset",
              fontSize: 13,
              height: "unset",
            }}
            listDropdownStyle={{ top: "unset" }}
            handler={handleCountrySelect}
            options={countries}
            selected={selectedCountryIds}
            flat
            multiple
            selectedRight
          />
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <span style={{ marginRight: 10 }}>Site Type:</span>
          {scopeOptions.map((scope, i) => (
            <Checkbox
              checked={selectedScopeIds.includes(scope.id)}
              label={scope.name}
              key={i}
              checkHandler={(check) => handleCheck(scope.id, check)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    borderRadius: 10,
    backgroundColor: colors.opaqueWhite,
    alignItems: "center",
  },
  detailButton: {
    width: 50,
    fontSize: 10,
    height: 20,
    borderRadius: 20,
    color: colors.darkBlue,
    lineHeight: 1,
  },
  updateButton: {
    justifyContent: "flex-end",
    gap: 10,
    height: 30,
    fontSize: 11,
    width: 160,
    position: "left",
    zIndex: 2,
    padding: "5px 10px",
  },
  selectionChoices: {
    justifyContent: "space-between",
    gap: 10,
    height: 30,
    fontSize: 10,
    width: "150 px",
    position: "relative",
    zIndex: 2,
    padding: "5px 10px",
  },
  selectionChoicesDropDown: {
    width: "100%",
    position: "absolute",
    top: 0,
    borderRadius: 20,
    backgroundColor: colors.opaqueBrightBlue,
    padding: "25px 10px 10px",
    display: "flex",
    fontSize: 10,
    flexDirection: "column",
    gap: 5,
    color: colors.black,
  },
  link: {
    color: colors.black,
    cursor: "pointer",
    textDecoration: "none",
  },
});