import { Icon } from "@/lib";
import { StaticMap } from "@/lib/map";
import { t } from "@/utils";
import * as React from "react";
import "./index.scss";
import { colors, StyleSheet } from "@/utils/style";
import { taskProcess } from "@/project/measure/AllSitesTable";
import shrub from "@/assets/icons/shrub.png";
import urban from "@/assets/icons/urban.png";
import forest from "@/assets/icons/forest.png";
import shark from "@/assets/icons/shark.png";
import dove from "@/assets/icons/dove.png";
import butterfly from "@/assets/icons/butterfly.png";
import { mdiChevronDown, mdiChevronUp, mdiEarth } from "@mdi/js";
import { MaterialityDetail } from "@/project/site/overview/MaterialityDetail";
import { AppContext } from "@/context";
import { accumulatePriorityScore } from "@/utils/calculatePriorityScore";
import { isThreatenedSpecies, isKeyStoneSpecies } from "@/utils";
import CryptoJS from "crypto-js";
import { calculateNaturalHabitatPercentage } from "./utils";
import { getRatingTextFromNumber } from "@/utils/getRatingText";
import { useLocation } from "react-router-dom";

interface SiteOverviewProps {
  site: INewSiteDetailed;
  bbox: number[];
  layers: string[];
  onLayerLoad: (id: string, layer: IKeyAreaLayer) => void;
}

const habitatMap: Record<string, string> = {
  "Urban Areas": urban,
  "Shrubland – Temperate": shrub,
  "Forest – Temperate": forest,
};

export const SiteOverview: React.FC<SiteOverviewProps> = ({
  site,
  bbox,
  layers,
  onLayerLoad,
}) => {
  const [showMateriality, setShowMateriality] = React.useState(false);
  const { state } = React.useContext(AppContext);
  const waterRisk = React.useMemo(
    () => site.assessment?.kpis?.overall_water_risk ?? 0,
    [site]
  );
  const [waterRiskCategory, setwaterRiskCategory] = React.useState("No data");
  // const [protectedAreasCount, setProtectedAreasCount] = React.useState<number>(0);
  const [BSACount, setBSACount] = React.useState<number>(0);
  const [treeCoverLoss, setTreeCoverLoss] = React.useState<string>("N/A");
  const [dominantDriver, setDominantDriver] = React.useState<string>("N/A");
  const [waterRiskImpact, setWaterRiskImpact] = React.useState<number>(0);
  const [waterRiskDependency, setWaterRiskDependency] =
    React.useState<number>(0);
  const [deforestationRiskImpact, setDeforestationRiskImpact] =
    React.useState<number>(0);
  const [deforestationRiskDependency, setDeforestationRiskDependency] =
    React.useState<number>(0);
  const [msaPercentage, setMsaPercentage] = React.useState<string>("N/A");
  const [msaDeltaPercentage, setMsaDeltaPercentage] =
    React.useState<string>("N/A");
  const [naturalHabitatPercentage, setNaturalHabitatPercentage] =
    React.useState<string>("N/A");
  const [nonNaturalHabitatPercentage, setNonNaturalHabitatPercentage] =
    React.useState<string>("N/A");
  const [nonNaturalSealedPercentage, setNonNaturalSealedPercentage] =
    React.useState<string>("N/A");
  const [deforestation, setDeforestation] = React.useState({
    year: "N/A",
    deforestation_category: "No deforestation",
  });

  // const project = useProject();
  // const team = useTeam();
  // const report = project.reports[project.latest_report_id] as IAssessmentReport;
  const priorityScoreElement = React.useRef(null);
  const [height, setHeight] = React.useState(0);
  const economicActivitiesIds = site.economic_activities
    .map((ea) => ea.id)
    .join(",");
  console.log("economicActivitiesIds ===>>> ", economicActivitiesIds);
  console.log("deployed from new dev server 662024");
  const [keystoneSpeciesLength, setKeystoneSpeciesLength] = React.useState(0);
  const [threatenedAndKeystoneSpecies, setThreatenedAndKeystoneSpecies] = React.useState(0);
  const [threatenedSpecies, setThreatenedSpecies] = React.useState(0);
  const [BSS, setBSS] = React.useState(0);
  const [speciesRicheness, setSpeciesRicheness] = React.useState(0);
  const [habitatDict, setHabitatDict] = React.useState([]);
  site.calculatedPriorityScore = parseInt(localStorage.getItem("pscore"));
  const encryptedToken = CryptoJS.AES.encrypt(
    process.env.API_KEY,
    process.env.SECRET_KEY
  ).toString();
  const header = {
    headers: {
      Authorization: `Bearer ${encryptedToken}`,
    },
  };

  const waterRiskPercentage = React.useMemo(() => {
    const _waterRisk = waterRisk > 0 ? Math.round((waterRisk / 255) * 100) : 0;
    const invertedWaterRisk = 100 - _waterRisk;
    console.log("invertedWaterRisk ===>>> ", invertedWaterRisk);
    return invertedWaterRisk;
  }, [waterRisk]);

  //   0 < new value % < 20 -> LOW
  // 20 <= new value % < 40 -> LOW-MEDIUM
  // 40 <= new value % < 60 -> MEDIUM-HIGH
  // 60 <= new value % < 80 -> HIGH
  // 80 <= new value % <= 100 -> VERY HIGH
  const getStringRatingWaterRisk = (value: number) => {
    if (value > 0 && value < 20) {
      return "Low";
    } else if (value >= 20 && value < 40) {
      return "Low-Medium";
    } else if (value >= 40 && value < 60) {
      return "Medium-High";
    } else if (value >= 60 && value < 80) {
      return "High";
    } else if (value >= 80 && value <= 100) {
      return "Very High";
    }
  };

  const baseMap = state.projects.exploreMapBaseMap;
  const mapRasters = state.projects.exploreMapRasters;

  const fetchWaterRiskImpact = async () => {
    // const siteTypeId = site.site_type.id ? site.site_type.id : 1;
    const url = `${process.env.KUYUA_APP_BACKEND_URL}/impacts/max?economic_activities_ids=${economicActivitiesIds}
    &impact_names=water_use,water_pollutants`;
    try {
      const response = await fetch(url, header);
      const data = await response.json();
      console.log("Water Risk MAX impact ===>>> ", data);
      if (data.max_impact) {
        setWaterRiskImpact(data.max_impact);
      }
    } catch (error) {
      console.error("Error fetching water risk impact:", error);
    }
  };

  const fetchDeforestationRiskImpact = async () => {
    const url = `${process.env.KUYUA_APP_BACKEND_URL}/impacts/max?economic_activities_ids=${economicActivitiesIds}&impact_names=terrestrial_ecosystem_use,disturbances`;
    try {
      const response = await fetch(url, header);
      const data = await response.json();
      console.log("Deforestation Risk MAX impact ===>>> ", data);
      if (data.max_impact) {
        setDeforestationRiskImpact(data.max_impact);
      }
    } catch (error) {
      console.error("Error fetching deforestation risk impact:", error);
    }
  };

  const fetchWaterRiskDependency = async () => {
    const url = `${process.env.KUYUA_APP_BACKEND_URL}/dependencies/max?economic_activities_ids=${economicActivitiesIds}&dependency_names=surface_water,ground_water,water_flow_maintenance,water_quality`;
    try {
      const response = await fetch(url, header);
      const data = await response.json();
      console.log("Water Risk MAX Dependency ===>>> ", data);
      if (data.max_dependency) {
        setWaterRiskDependency(data.max_dependency);
      }
    } catch (error) {
      console.error("Error fetching water risk Dependency:", error);
    }
  };

  const fetchDeforestationRiskDependency = async () => {
    const url = `${process.env.KUYUA_APP_BACKEND_URL}/dependencies/max?economic_activities_ids=${economicActivitiesIds}&dependency_names=climate_regulation,flood_and_storm_protection,filtration,mass_stabilisation_and_erosion_control,mediation_of_sensory_impacts`;
    try {
      const response = await fetch(url, header);
      const data = await response.json();
      console.log("Deforestation Risk MAX Dependency ===>>> ", data);
      if (data.max_dependency) {
        setDeforestationRiskDependency(data.max_dependency);
      }
    } catch (error) {
      console.error("Error fetching Deforestation risk Dependency:", error);
    }
  };

  const fetchMSA = async () => {
    const url = `${process.env.GEO_SERVER_URL}/msa?lat=${site.latlng.lat}&lng=${site.latlng.lng}`;
    console.log(url);
    try {
      const response = await fetch(url, header);
      const data = await response.json();
      console.log("msa ===>>> ", data);
      if (data.msa) {
        setMsaPercentage(Number(data.msa).toFixed());
      }
    } catch (error) {
      console.error("Error fetching protected areas:", error);
    }
  };
  const fetchMsaDelta = async () => {
    const url = `${process.env.GEO_SERVER_URL}/msa-delta?lat=${site.latlng.lat}&lng=${site.latlng.lng}`;
    try {
      const response = await fetch(url, header);
      const data = await response.json();
      if (data.msa_delta) {
        setMsaDeltaPercentage(Number(data.msa_delta).toFixed());
      }
    } catch (error) {
      console.error("Error fetching protected areas:", error);
    }
  };
  const fetchDeforestation = async () => {
    const url = `${process.env.GEO_SERVER_URL}/deforestation?lat=${site.latlng.lat}&lng=${site.latlng.lng}`;
    try {
      const response = await fetch(url, header);
      const data = await response.json();
      if (data.deforestation_category && data.year) {
        setDeforestation(data);
      }
    } catch (error) {
      console.error("Error fetching deforestation:", error);
    }
  };
  const fetchWaterRisk = async () => {
    const url = `${process.env.GEO_SERVER_URL}/water-risk?lat=${site.latlng.lat}&lng=${site.latlng.lng}`;
    try {
      const response = await fetch(url, header);
      const data = await response.json();
      if (data.category) {
        //set water risk category
        setwaterRiskCategory(data.category);
      }
    } catch (error) {
      console.error("Error fetching water risk:", error);
    }
  };
  const fetchSpecies = async () => {
    const url = `${process.env.GEO_SERVER_URL}/species?decimalLatitude=${site.latlng.lat}&decimalLongitude=${site.latlng.lng}`
    console.log(url);
    try {
      const response = await fetch(url, header);
      const data = await response.json();
      //console.log('species ===>>> ', data);
      if (data) {
        setSpeciesRicheness(data.total);
        setKeystoneSpeciesLength(data.key_stone_species_count);
        setThreatenedSpecies(data.threatened_species_count);
        const tmpThreatenedAndKeystoneSpecies = data.threatened_species_count + data.key_stone_species_count;
        setThreatenedAndKeystoneSpecies(tmpThreatenedAndKeystoneSpecies);
        var tmpBss = data.key_stone_species_count === 0 ? 0 : (tmpThreatenedAndKeystoneSpecies / data.key_stone_species_count) * 100;
        console.log('tmpBss ===>>> ', tmpBss);
        setBSS(tmpBss);
        if (window) {
          window.localStorage.setItem('totalSpecies', data.total);
          window.localStorage.setItem('threatenedSpecies', data.threatened_species_count);
          window.localStorage.setItem('keystoneSpecies', data.key_stone_species_count);
        }
      }

    } catch (error) {
      console.error('Error fetching species:', error);
    }
  };
  const fetchHabitats = async () => {
    const url = `${process.env.GEO_MIND_URL}/habitats/habitat_classes?lat=${site.latlng.lat}&lng=${site.latlng.lng}&radius=${site.parameters.outer_range_meters}`;
    try {
      const response = await fetch(url, header);
      const data = await response.json();
      if (data) {
        //set habitat dict
        setHabitatDict(data);
      }
    } catch (error) {
      console.error("Error fetching water risk:", error);
    }
  };
  const [changeAverage, setChangeAverage] = React.useState("");
  const fetchChangeAverage = async () => {
    const url = `${process.env.GEO_SERVER_URL}/land-degradation/change-vs-ten-year-average?lat=${site.latlng.lat}&lng=${site.latlng.lng}`;
    try {
      const response = await fetch(url, header);
      const data = await response.json();
      setChangeAverage(data.cya);
    } catch (error) {
      console.error("Error fetching cya:", error);
    }
  };

  const [netPrimary, setNetPrimary] = React.useState("");
  const fetchNetPrimary = async () => {
    const url = `${process.env.GEO_SERVER_URL}/land-degradation/net-primary-productivity?lat=${site.latlng.lat}&lng=${site.latlng.lng}`;
    try {
      const response = await fetch(url, header);
      const data = await response.json();
      console.log("Data NPP --->>>", data.npp);
      switch (true) {
        case data.npp >= 1 && data.npp < 50:
          setNetPrimary("Very Low");
          break;
        case data.npp >= 50 && data.npp < 100:
          setNetPrimary("Low");
          break;
        case data.npp >= 100 && data.npp < 150:
          setNetPrimary("Medium");
          break;
        case data.npp >= 150 && data.npp < 200:
          setNetPrimary("High");
          break;
        case data.npp >= 200 && data.npp <= 255:
          setNetPrimary("Very High");
          break;
        case data.npp === "N/A" || !data.npp:
          setNetPrimary("N/A");
          break;
        default:
          setNetPrimary("Out of range");
          break;
      }
    } catch (error) {
      console.error("Error fetching cya:", error);
    }
  };

  React.useEffect(() => {
    fetchWaterRiskImpact();
    fetchDeforestationRiskImpact();
    fetchWaterRiskDependency();
    fetchDeforestationRiskDependency();
    fetchChangeAverage();
    fetchNetPrimary();
    fetchMSA();
    fetchMsaDelta();
    fetchDeforestation();
    fetchWaterRisk();
    fetchSpecies();
    fetchHabitats();
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      const radius = site.parameters.inner_range_meters || 2000; // Radius of the circle in meters
      const url = `${process.env.GEO_SERVER_URL}/protected-areas/circle?count=true&radius=${radius}&lng=${site.latlng.lng}&lat=${site.latlng.lat}`;
      console.log(url);
      try {
        const response = await fetch(url, header);
        const data = await response.json();
        console.log("BSA Count ===>>> ", data);
        setBSACount(data.count);
      } catch (error) {
        console.error("Error fetching protected areas:", error);
      }
    };

    fetchData();
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      // Construct the URL for the GeoServer request
      //9.190496, 49.852156
      // const url = `${process.env.GEO_SERVER_URL}/tree-cover-loss?latlng=9.190496,49.852156`;

      const url = `${process.env.GEO_SERVER_URL}/tree-cover-loss?latlng=${site.latlng.lng},${site.latlng.lat}`;
      console.log(url);
      try {
        const response = await fetch(url, header);
        const data = await response.json();
        console.log("tree cover loss ===>>> ", data);
        if (data.tree_cover_loss) {
          let treeCoverLossRate = "Low";
          if (data.tree_cover_loss >= 1) {
            treeCoverLossRate = "High";
          }
          setTreeCoverLoss(treeCoverLossRate);
        }

        let _dominantDriver = "N/A";
        switch (data.tree_cover_loss) {
          case 5:
            _dominantDriver = "Urbanization";
            break;
          case 4:
            _dominantDriver = "Wildfire";
            break;
          case 3:
            _dominantDriver = "Forestry";
            break;
          case 2:
            _dominantDriver = "Shifting agriculture";
            break;
          case 1:
            _dominantDriver = "Commodity-driven";
            break;
        }
        setDominantDriver(_dominantDriver);
      } catch (error) {
        console.error("Error fetching tree cover loss!", error);
      }
    };

    fetchData();
  }, []);

  React.useEffect(() => {
    console.log("site  ===>>> ", site);
    const naturalHabitatPercentage = calculateNaturalHabitatPercentage(
      site.assessment?.habitats ?? []
    );
    setNaturalHabitatPercentage(
      parseFloat(naturalHabitatPercentage).toFixed(1) + "%"
    );
    const nonNaturalHabitatPercentage =
      100 - parseFloat(naturalHabitatPercentage);
    setNonNaturalHabitatPercentage(`${nonNaturalHabitatPercentage.toFixed(1)}%`);
    const urbanAreasObj = site.assessment?.habitats?.filter(
      (habitat) => habitat.name === "Urban Areas"
    );
    if (urbanAreasObj && Array.isArray(urbanAreasObj) && urbanAreasObj.length) {
      const computedUrbanAreasValue = (
        (urbanAreasObj[0].percentage * 100) /
        2
      ).toFixed(1);
      setNonNaturalSealedPercentage(`${computedUrbanAreasValue}%`);
    }
  }, [site]);

  React.useEffect(() => {
    priorityScoreElement.current &&
      setHeight(priorityScoreElement.current.clientHeight);
  }, []);

  return (
    <>
      <div style={styles.gridContainer}>
        <div style={{ ...styles.gridItem, gridRow: 1, gridColumn: 1 }}>
          <CapitalText label={"Site Name"} right={site.name} />
        </div>
        <div
          style={{ ...styles.gridItem, gridRow: 1, gridColumn: 2 }}
          ref={priorityScoreElement}
        >
          <CapitalText label={"Site Type"} right={site.site_type.name} />
        </div>
        <div
          style={{ ...styles.gridItem, gridRow: 1, gridColumn: 3 }}
          ref={priorityScoreElement}
        >
          <CapitalText label={t("ui.address")} />
          <div style={{ fontSize: 15, textTransform: "capitalize" }}>
            {site.address?.street_address + ", "}
            {site.address?.zip_code} {site.address?.city + ", "}
            {site.address?.country}
          </div>
        </div>
        <div style={{ ...styles.gridItem, gridRow: 2, gridColumn: 1 }}>
          <CapitalText label={"Progress (Actions)"} right={taskProcess(site)} />
        </div>
        <div
          style={{ ...styles.gridItem, gridRow: 2, gridColumn: 2 }}
          ref={priorityScoreElement}
        >
          <CapitalText
            label={t("ui.priority_score")}
            right={site.calculatedPriorityScore ?? "-"}
          />
        </div>
        <div style={{ position: "relative" }}>
          <div
            style={{
              ...styles.gridItem,
              ...(showMateriality && styles.gridItemOpen),
              gridRow: 2,
              gridColumn: 3,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onClick={() => setShowMateriality((prev) => !prev)}
          >
            <span
              style={{
                textTransform: "uppercase",
                fontSize: 17,
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 15,
              }}
            >
              {t("ui.site_detail.materiality_profile")}
            </span>
            <Icon
              color={colors.white}
              path={showMateriality ? mdiChevronUp : mdiChevronDown}
            />
          </div>
          {showMateriality && <MaterialityDetail site={site} />}
        </div>

        <div style={{ gridRow: 3, gridColumn: 1 }}>
          <h3>Nature Condition & Resilience</h3>
        </div>

        <div style={{ ...styles.gridItem, gridRow: 4, gridColumn: 1 }}>
          <CapitalText label={"Condition"} />
          <IconInformationRow
            label={t("ui.site_detail.species_abundance")}
            right={`${msaPercentage}%`}
          />
          <IconInformationRow
            label={"Projected Change in Biodiversity Intactness"}
            right={`${msaDeltaPercentage}%`}
          />
          <IconInformationRow
            label={"Species Richness"}
            right={speciesRicheness}
          />
          <IconInformationRow
            label={t("ui.protected_areas_count")}
            right={BSACount}
          />
          <IconInformationRow
            label="Vegetation Productivity 2023"
            right={netPrimary}
          />
          <IconInformationRow
            label="Trend in Vegetation Productivity"
            right={changeAverage}
          />
        </div>
        <div style={{ ...styles.gridItem, gridRow: 4, gridColumn: 2 }}>
          <CapitalText label={"Resilience"} />
          <IconInformationRow
            label={"Threatened Species"}
            right={threatenedSpecies}
          />
          <IconInformationRow
            label={"Keystone Species Richness"}
            right={keystoneSpeciesLength}
          />
          <IconInformationRow
            label={"Keystone Species @ Risk"}
            right={BSS.toFixed(1) + " %"}
          />
        </div>

        <div style={{ gridRow: 5, gridColumn: 1 }}>
          <h3>Nature-Related Risk</h3>
        </div>

        <div style={{ ...styles.gridItem, gridRow: 6, gridColumn: 1 }}>
          <CapitalText label={"Water Risk"} />
          {/* hard coded for now */}
          <IconInformationRow
            label={"Water Risk"}
            right={waterRiskCategory}
          />
          <IconInformationRow
            label={"Impact Level"}
            right={getRatingTextFromNumber(waterRiskImpact)}
          />
          <IconInformationRow
            label={"Dependency Level"}
            right={getRatingTextFromNumber(waterRiskDependency)}
          />
        </div>
        <div style={{ ...styles.gridItem, gridRow: 6, gridColumn: 2 }}>
          <CapitalText label={"Deforestation Risk"} />
          <IconInformationRow
            label={t("ui.deforestation")}
            right={deforestation.year}
          />
          <IconInformationRow
            label={"Dominant Driver"}
            right={deforestation.deforestation_category}
          />
          <IconInformationRow
            label={"Impact Level"}
            right={getRatingTextFromNumber(deforestationRiskImpact)}
          />
          <IconInformationRow
            label={"Dependency Level"}
            right={getRatingTextFromNumber(deforestationRiskDependency)}
          />
        </div>

        <div style={{ gridRow: 7, gridColumn: 1 }}>
          <h3>Nature Fragmentation & Extend</h3>
        </div>

        <div style={{ ...styles.gridItem, gridRow: 8, gridColumn: 1 }}>
          <CapitalText label={"Fragmentation"} />
          {habitatDict?.map((habitat) => {
            return (
              <IconInformationRow
                label={habitat.name}
                iconSvg={habitatMap[habitat.name]}
                iconPath={!habitatMap[habitat.name] ? mdiEarth : null}
                right={`${habitat.percentage}%`}
                key={habitat.id}
              />
            );
          })}
        </div>
        <div style={{ ...styles.gridItem, gridRow: 8, gridColumn: 2 }}>
          <CapitalText label={"Extend"} />
          <IconInformationRow
            label={"Natural Habitat"}
            right={naturalHabitatPercentage}
          />
          <IconInformationRow
            label={"Non-Natural Habitat"}
            right={nonNaturalHabitatPercentage}
          />
          <IconInformationRow
            label={"Land & Soil Sealing"}
            right={nonNaturalSealedPercentage}
          />
        </div>

        <div
          style={{
            ...styles.gridItem,
            gridRowStart: 4,
            gridRowEnd: 9,
            gridColumn: 3,
            position: "relative",
            minHeight: 500,
            marginBottom: 20,
          }}
        >
          <StaticMap
            bbox={bbox}
            center={site.latlng}
            interactive={true}
            circles={[
              {
                center: site.latlng,
                id: `site-${site.id}-inner`,
                radius: site.parameters.inner_range_meters,
              },
              {
                center: site.latlng,
                id: `site-${site.id}-outer`,
                radius: site.parameters.outer_range_meters,
              },
            ]}
            layers={layers}
            onLayerLoad={onLayerLoad}
            pins={[
              {
                center: site.latlng,
                id: "site-" + site.id,
                text: site.name ?? site.site_id,
              },
            ]}
            rasters={mapRasters}
            baseMapUri={baseMap}
            zoom={7}
          />
        </div>
      </div>
    </>
  );
};

const CapitalText: React.FC<{ label: string; right?: string | number }> = ({
  label,
  right,
}) => {
  return (
    <div
      style={{
        textTransform: "uppercase",
        fontSize: 17,
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 15,
      }}
    >
      <span>{label}</span>
      <span style={{ color: colors.brightBlue }}>{right}</span>
    </div>
  );
};

const IconInformationRow: React.FC<{
  label: string;
  right?: string | number;
  iconPath?: string;
  small?: boolean;
  iconSvg?: string;
}> = ({ label, right, iconPath, small, iconSvg }) => {
  return (
    <div
      style={{
        fontSize: 13,
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 15,
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 10,
          ...(small && { fontSize: 10 }),
        }}
      >
        {iconSvg && (
          <img
            src={iconSvg}
            alt="PNG Icon"
            style={small ? { height: 25, width: 25 } : {}}
          />
        )}
        {iconPath && (
          <Icon
            path={iconPath}
            style={small ? { height: 25, width: 25 } : {}}
            color={"white"}
          />
        )}
        <span>{label}</span>
      </div>
      <span style={{ color: colors.brightBlue, textTransform: "capitalize" }}>
        {right}
      </span>
    </div>
  );
};

const styles = StyleSheet.create({
  gridContainer: {
    display: "grid",
    columnGap: 20,
    rowGap: 20,
    gridTemplateRows: "auto auto auto auto 1fr",
    gridTemplateColumns: "30% 30% 40%",
  },
  gridItem: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    backgroundColor: colors.opaqueBlue,
    padding: "20px 15px",
  },
  gridItemOpen: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
});
