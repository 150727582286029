import React from "react";
import { colors, style, StyleSheet } from "@/utils/style";
import PriorityTriangle from "@/project/sleap/Assess/PriorityTriangle";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
import { Icon } from "@/lib";
import Bubble from "@/lib/Bubble";
import { ProgressBar } from "@/project/understand/DetailContent";
// import { useDependencies, useImpacts } from "@/context";
import { AssessTileProps, BarProps } from "@/types/sleap";
import {SleapContext} from "@/context/SleapContext";
import { calculatePriorityScore } from "@/utils/calculatePriorityScore";
import useFetchDependencies  from "@/utils/useFetchDependencies";
import useFetchImpacts from "@/utils/useFetchImpacts";
import { Rating } from "@/types/utils.d";
import { getBubbleBarColor } from "@/utils/getBubbleBarColor";
import { getRatingText } from "@/utils/getRatingText";

const AssessTile: React.FC<AssessTileProps> = ({ site, isOpen, openHandler }) => {

    const { sleapDispatch } = React.useContext(SleapContext);
    const economicActivitiesIds = site.economic_activities.map(ea => ea.economic_activity_id).join(',');
    const {
        totalUniqueImpacts,
        totalUniqueImpactsWithRating,
        restructuredImpactsGeoServer,
        loading,
        error,
      } = useFetchImpacts(economicActivitiesIds);
    const {
    totalUniqueDependencies,
    totalUniqueDependenciesWithRating,
    restructuredDependenciesGeoServer,
} = useFetchDependencies(economicActivitiesIds);

    const setTargetLocationSite = (): void => {

        sleapDispatch({ targetSiteId: site.id });
    };

    return (
        <div
          style={{ ...styles.assessTile, ...(isOpen ? styles.assessTileActive : {}) }}
          onClick={setTargetLocationSite}
          id={`assess-${site.id}`}
        >
            <div style={{ ...styles.assessTileRow, ...{ height: 60 } }}>
                <div style={{ flex: 1 }}>
                  {site.site_name}
                </div>
                <div style={{ flex: 1, ...style.centerFlex, justifyContent: 'space-between' }}>
                  {site.site_id}
                </div>
                <div>
                <PriorityTriangle value={Number(site.priority_score).toFixed()} />
                </div>
                <div style={{ flex: 1, textAlign: "right" }}>
                <Icon
                    style={{ ...styles.chevron }}
                    color="white"
                    path={isOpen ? mdiChevronUp : mdiChevronDown}
                    size={32}
                    onClick={() => isOpen ? openHandler(null) : openHandler(site.id)}
                />
                </div>
            </div>
            {isOpen && 
                <>
                    { restructuredImpactsGeoServer && restructuredDependenciesGeoServer && <>
                    <div style={{ ...styles.assessTileRow, ...styles.assessTileDetailRow, ...{ height: 80 } }}>
                        <div style={{ width: 85 }}>IMPACTS</div>
                        <div>
                            <Bubble count={totalUniqueImpactsWithRating} countTotal={12} size='sm' style={{ color: colors.white, backgroundColor: getBubbleBarColor([...restructuredImpactsGeoServer, ...restructuredDependenciesGeoServer]) }} />
                        </div>
                        <div>
                             {
                                 restructuredImpactsGeoServer.map(impact => (
                                     <div style={{fontSize: '12px'}}>{getRatingText(impact.rating as Rating)}: {impact.values.length}</div>
                                 ))
                             }
                        </div>
                    </div>
                    <div style={{ ...styles.assessTileRow, ...styles.assessTileDetailRow, ...{ height: 80 } }}>
                        <div style={{ width: 85 }}>DEPENDENCIES</div>
                        <div>
                            <Bubble count={totalUniqueDependenciesWithRating} countTotal={21} size='sm' style={{ color: colors.white, backgroundColor: getBubbleBarColor([...restructuredImpactsGeoServer, ...restructuredDependenciesGeoServer]) }} />
                        </div>
                        <div>
                            {
                                 restructuredDependenciesGeoServer.map(dependency => (
                                     <div style={{fontSize: '12px'}}>{getRatingText(dependency.rating as Rating)}: {dependency.values.length}</div>
                                 ))
                             }
                        </div>
                    </div>
                    </>}
                </>
            }
        </div >

    );
};

export const Bar: React.FC<BarProps> = ({
        rating, values, totalAvailable, backgroundColor, color, style, barColor
    }) => {

    const availableValues = calculatePriorityScore(rating as Rating, values.length);
    const completed = availableValues / totalAvailable * 100;

//    no need for opacity for now but we may need it later
    // let opacity = 0.4;
    // switch (rating) {
    //     case Rating.VERY_HIGH:
    //         opacity = 1;
    //         break;
    //     case Rating.HIGH:
    //         opacity = 0.7;
    //         break;
    // }

    return (
        <div style={style}>
            <ProgressBar
                backgroundColor={backgroundColor}
                color={color}
                barColor={barColor || colors.white}
                completed={completed}
                label={availableValues}
                // opacity={opacity}
            />
        </div>

    );
};

const styles = StyleSheet.create({
    assessTile: {
        backgroundColor: colors.mediumBlue,
        color: 'white',
    },
    assessTileActive: {
        backgroundColor: colors.opaqueBlue,
    },
    assessTileRow: {
        width: '100%',
        padding: '20px 15px',
        fontSize: 12,
        display: 'flex',
        justifyContent: 'space-between',
    },
    assessTileDetailRow: {
        paddingLeft: 25,
    },
    chevron: {
        cursor: 'pointer',
    },
});

export default AssessTile;
