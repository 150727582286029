import { useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';

const useFetchDependencies = (economicActivitiesIds:string) => {
  const [totalUniqueDependencies, setTotalUniqueDependencies] = useState(null);
  const [totalUniqueDependenciesWithRating, setTotalUniqueDependenciesWithRating] = useState(null);
  const [restructuredDependenciesGeoServer, setRestructuredDependenciesGeoServer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();
const header = {
    headers: {
        'Authorization': `Bearer ${encryptedToken}`
    }
};

   useEffect(() => {
    const fetchDependencies = async () => {
      const url = `${process.env.KUYUA_APP_BACKEND_URL}/dependencies?economic_activities_ids=${economicActivitiesIds}`;
      try {
        const response = await fetch(url, header);
        const data = await response.json();
        if (data.dependencies && data.dependencies.totalUnique) {
          setTotalUniqueDependencies(data.dependencies.totalUnique);
          setTotalUniqueDependenciesWithRating(data.dependencies.totalUniqueWithRating);
          setRestructuredDependenciesGeoServer(data.dependencies.restructuredItems);
          // save into local storage
          localStorage.setItem('totalUniqueDependencies', JSON.stringify(data.dependencies.totalUnique));
          localStorage.setItem('totalUniqueDependenciesWithRating', JSON.stringify(data.dependencies.totalUniqueWithRating));
          localStorage.setItem('restructuredDependenciesGeoServer', JSON.stringify(data.dependencies.restructuredItems));
        } else {
          console.log('No dependencies found for this site');
        }
      } catch (error) {
        console.error('Error fetching dependencies:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (economicActivitiesIds.length > 0) {
      fetchDependencies();
    }
  }, [economicActivitiesIds]);

  return {
    totalUniqueDependencies,
    totalUniqueDependenciesWithRating,
    restructuredDependenciesGeoServer,
    loading,
    error,
  };
};

export default useFetchDependencies;