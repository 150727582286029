import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext, NavigationEnabledChanged } from '@/context';
import { t } from '@/utils';
import { Card, Grid } from '@/lib';
import { PinMap } from '@/lib/map';


function Project(props: { project: IProject }) {
    const { state } = React.useContext(AppContext);
    const navigate = useNavigate();
    const team = state.config.team;
    const project = props.project;
    const minimap = project.minimap;
    const onClick = () => navigate(`/${team.slug}/${project.slug}`);

    return (
        <Card inset="0" style={{ cursor: 'pointer' }} onClick={onClick}>
            <div style={{ background: '#E5DDD3', display: 'flex', alignItems: 'center', overflow: 'hidden', justifyContent: 'center', height: '180px', width: '100%' }}>
                {minimap && <PinMap
                    bbox={minimap.bbox}
                    pins={minimap.pins}
                    size={{ width: 360, height: 240 }} />}
            </div>

            <div style={{ padding: '1rem' }}>
                <strong>{project.name}</strong>

                <div style={{ color: '#999', marginTop: '.33rem' }}>
                    <span>{project.site_count} {t(project.site_count === 1 ? 'site' : 'site_plural')}</span>
                </div>
            </div>
        </Card>
    );
}


export default function ProjectPickerPage(): JSX.Element {
    const { dispatch, state } = React.useContext(AppContext);
    const navigate = useNavigate();
    const padding = { xs: '.5rem', sm: '.5rem', md: '1rem', lg: '2rem', xl: '2rem' }[state.layoutSize];
    const itemsPerRow = { xs: 2, sm: 2, md: 3, lg: 3, xl: 5 }[state.layoutSize];
    const team = state.config.team;
    const projects = React.useMemo(() => Object
        .values(team.projects)
        .sort((a, b) => a.name.localeCompare(b.name)), [team.projects]);

    React.useEffect(() => {
        document.title = `${t('pages.project_picker')} — kuyua`;

        if (projects.length === 1) {
            navigate(`/${team.slug}/${projects[0].slug}/understand`);
            return;
        }

        dispatch({ type: NavigationEnabledChanged, navigationEnabled: false });

        return () => dispatch({ type: NavigationEnabledChanged, navigationEnabled: true });
    }, []);

    if (projects.length === 1) {
        return undefined;  // Disables flashing of the content below
    }

    return (
        <div style={{ padding: `6rem ${padding} ${padding} ${padding}` }}>
            <div style={{ textAlign: 'center', fontSize: '1.2rem', padding: '1rem 0 3rem 0' }}>
                Nice to see you again! Please choose a project to work on from below.
            </div>

            <Grid alignItems="stretch" itemsPerRow={itemsPerRow} style={{ margin: 'auto', maxWidth: '1600px' }}>
                {projects.map(p => <Project key={p.id} project={p} />)}
            </Grid>
        </div>
    );
}
