import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import CryptoJS from "crypto-js";
import useFetchImpacts from "@/utils/useFetchImpacts";
import useFetchDependencies from "@/utils/useFetchDependencies";
import { AppContext, ToastAdded, useTeam } from "@/context";
import { t } from "@/utils";

// const impactsQuestions = [
//   "GHG Emissions",
//   "Non-GHG Air Pollutants",
//   "Solid Waste",
//   "Soil Pollution",
//   "Water Pollution",
//   "Terrestrial Ecosystem Use",
//   "Freshwater Ecosystem Use",
//   "Marine Ecosystem Use",
//   "Water Use",
//   "Other Resources Use",
//   "Disturbances",
//   "Biological Alterations Interferences",
// ];

// const dependenciesQuestions = [
//   "Animal based energy",
//   "Pollination",
//   "Pest control",
//   "Disease control",
//   "Genetic materials",
//   "Maintain nursery habitats",
//   "Ground water",
//   "Surface water",
//   "Water quality",
//   "Mass stabilization and erosion control",
//   "Flood and storm protection",
//   "Water flow maintenance",
//   "Buffering and attenuation mass flows",
//   "Bioremediation",
//   "Filtration",
//   "Soil quality",
//   "Dilution by atmosphere and ecosystems",
//   "Fibres and other materials",
//   "Climate regulation",
//   "Ventilation",
//   "Mediation of sensory impacts",
// ];

// Custom useDebounce hook

const questions = [
    "Have legally required environmental impact assessments or similar evaluations been conducted?",
    "Have externally audited environmental management systems (e.g., ISO or EMAS) or certification schemes been implemented?",
    "Have legally required actions to reduce impacts been implemented?",
];

// Common Styles
const containerStyle = {
    backgroundColor: "white",
    height: "100%",
    color: "black",
    padding: 20,
    borderRadius: 15,
};

const sectionStyle = {
    fontSize: "20px",
    fontWeight: "bold",
};

const flexRowStyle = {
    width: "100%",
    display: "flex",
    alignItems: "center",
};

const encryptedToken = CryptoJS.AES.encrypt(
    process.env.API_KEY,
    process.env.SECRET_KEY
).toString();

const header = {
    headers: {
        Authorization: `Bearer ${encryptedToken}`,
    },
};

const rating = {
    VH: "Very High",
    H: "High",
    M: "Medium",
    L: "Low",
};

const SiteInputTab: React.FC<{ site: INewSiteDetailed }> = ({ site }) => {
    const team = useTeam();
    const [answers, setAnswers] = useState({
        dependencies: {},
        impacts: {},
        common: {},
        new: {},
    });
    const [template, setTemplate] = useState(null);
    const [economicActivitiesIds, setEconomicActivitiesIds] = useState("");
    const [impactQuestions, setImpactQuestions] = useState([]);
    const [dependencyQuestions, setDependencyQuestions] = useState([]);
    const [newQuestionToAdd, setNewQuestionToAdd] = useState("");

    const { dispatch, state } = useContext(AppContext);

    // Fetch the site data and economic activity IDs
    const getSite = useCallback(async () => {
        try {
            const newDbUrl = `${process.env.KUYUA_APP_BACKEND_URL}/sites?company_id=${team.id}&site_name=${site.name}`;
            const response = await fetch(newDbUrl, header);
            const data = await response.json();
            const siteData = data.sites[0];

            const economicActivityIds = siteData.economic_activities
                .map((ea: any) => ea.economic_activity_id)
                .join(",");
            setEconomicActivitiesIds(economicActivityIds);
        } catch (error) {
            console.error("Error fetching site data:", error);
            dispatch({
                type: ToastAdded,
                toast: { kind: "error", text: t("ui.failed_to_load_sites") },
            });
        }
    }, [team.id, site.name, dispatch]);

    // Fetch and submit questions
    const submitQuestions = useCallback(
        async (answers: any) => {
            try {
                await fetch(`${process.env.KUYUA_APP_BACKEND_URL}/templates/create`, {
                    method: "POST",
                    body: JSON.stringify({
                        template: { data: answers, name: `${site.name}-${site.id}` },
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${encryptedToken}`,
                    },
                });
                dispatch({
                    type: ToastAdded,
                    toast: {
                        kind: "success",
                        text: "Changes saved successfully",
                    },
                });
            } catch (error) {
                console.error("Error submitting questions:", error);
            }
        },
        [answers, site.name, site.id]
    );

    // Fetch existing questions template
    const getQuestions = useCallback(async () => {
        try {
            const response = await fetch(
                `${process.env.KUYUA_APP_BACKEND_URL}/templates/getOne/${site.name}-${site.id}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${encryptedToken}`,
                    },
                }
            );
            const result = await response.json();
            const parsedData = JSON.parse(result.template.data);
            setTemplate(parsedData);
            setAnswers(parsedData);
        } catch (error) {
            console.error("Error fetching questions:", error);
        }
    }, [site.name, site.id]);

    useEffect(() => {
        if (site) {
            getSite();
            getQuestions();
        }
    }, [site, getSite, getQuestions]);

    // Fetch impacts and dependencies data using custom hooks
    const { restructuredImpactsGeoServer } = useFetchImpacts(
        economicActivitiesIds
    );
    const { restructuredDependenciesGeoServer } = useFetchDependencies(
        economicActivitiesIds
    );

    useEffect(() => {
        if (restructuredImpactsGeoServer?.length) {
            const impactQs = restructuredImpactsGeoServer.flatMap((im: any) =>
                im?.values?.map((val: any) => ({
                    value: rating[im.rating as keyof typeof rating],
                    label: val,
                }))
            );
            setImpactQuestions(impactQs);
        }

        if (restructuredDependenciesGeoServer?.length) {
            const dependencyQs = restructuredDependenciesGeoServer.flatMap(
                (dep: any) =>
                    dep?.values?.map((val: any) => ({
                        value: rating[dep.rating as keyof typeof rating],
                        label: val,
                    }))
            );
            setDependencyQuestions(dependencyQs);
        }
    }, [restructuredImpactsGeoServer, restructuredDependenciesGeoServer]);

    // Helper function to handle answer changes
    const handleChange = (
        section: string,
        data: {
            questionLabel: string;
            actual: string;
            comment: string;
            potential: string;
        }
    ) => {
        setAnswers((prevAnswers) => ({
            ...prevAnswers,
            [section]: {
                ...prevAnswers[section],
                [data.questionLabel]: data,
            },
        }));
    };

    // Select options component
    const SelectOptions: React.FC<{
        options: string[];
        onChange: (value: string) => void;
        value: string;
    }> = ({ options, onChange, value }) => (
        <select
            onChange={(e) => onChange(e.target.value)}
            style={{ width: "100%", borderRadius: 10, height: 30 }}
            value={value}
        >
            {options.map((option) => (
                <option key={option} value={option}>
                    {option}
                </option>
            ))}
        </select>
    );

    // InputRow component
    const InputRow: React.FC<{
        label: string;
        options: string[];
        answers: { [key: string]: any };
        section: string;
        handleChange: (section: string, data: any) => void;
        potential: string;
    }> = ({ label, options, answers, section, handleChange, potential }) => {
        const [comment, setComment] = useState(
            answers[section][label]?.comment || ""
        );
        // const value = answers[section][label]?.potential || "";
        const typingTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

        function formatName(name: string) {
            return name
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ");
        }

        const onChange = (data: any) => {
            handleChange(section, {
                ...data,
                potential,
                questionLabel: label,
            });
        };

        const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            const newComment = e.target.value;
            setComment(newComment);

            if (typingTimeoutRef.current) clearTimeout(typingTimeoutRef.current);

            typingTimeoutRef.current = setTimeout(() => {
                onChange({
                    questionLabel: label,
                    actual: answers[section][label]?.actual,
                    comment: newComment,
                });
            }, 2000);
        };

        return (
            <div style={flexRowStyle}>
                <div
                    style={{ width: "30%", fontStyle: "italic", fontWeight: "normal" }}
                >
                    {formatName(label)}
                </div>
                <div
                    style={{
                        width: "60%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        gap: 50,
                    }}
                >
                    <div style={{ width: "5%", textWrap: "nowrap" }}>{potential}</div>
                    <div style={{ width: "15%" }}>
                        <SelectOptions
                            options={options}
                            onChange={(value) =>
                                onChange({
                                    questionLabel: label,
                                    actual: value,
                                    comment: answers[section][label]?.comment,
                                })
                            }
                            value={answers[section][label]?.actual}
                        />
                    </div>
                    <div style={{ width: "15%" }}>
                        <textarea
                            onChange={handleCommentChange}
                            value={comment}
                            style={{ borderRadius: 10, padding: 0, height: 30 }}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const QuestionRow: React.FC<{ label: string; answers: any }> = ({
        label,
        answers,
    }) => {
        const [comment, setComment] = useState(
            answers["common"][label]?.comment || ""
        );
        const typingTimeoutRef = useRef(null);
        const textareaRef = useRef(null); // Ref to track the textarea element

        const onChange = (data: any) => {
            handleChange("common", data);
        };

        const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            const newComment = e.target.value;
            setComment(newComment);

            if (typingTimeoutRef.current) clearTimeout(typingTimeoutRef.current);

            typingTimeoutRef.current = setTimeout(() => {
                onChange({
                    questionLabel: label,
                    actual: answers["common"][label]?.actual,
                    comment: newComment,
                });
            }, 2000);
        };

        return (
            <div style={{ display: "flex", alignItems: "flex-start", width: "100%" }}>
                <div style={{ width: "50%", fontWeight: "bold" }}>{label}</div>
                <div style={{ display: "flex", width: "50%", gap: 5 }}>
                    <div style={{ width: "50%" }}>
                        <SelectOptions
                            onChange={(value) =>
                                onChange({
                                    questionLabel: label,
                                    actual: value,
                                    comment: answers["common"][label]?.comment,
                                })
                            }
                            options={["Yes", "No"]}
                            value={answers["common"][label]?.actual}
                        />
                    </div>
                    <div style={{ width: "50%" }}>
                        <textarea
                            ref={textareaRef} // Attach the ref to the textarea
                            onChange={handleCommentChange}
                            value={comment}
                            style={{
                                borderRadius: 10,
                                padding: 0,
                                height: 30,
                                width: "100%",
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const superUser = state.config.user.is_superuser;

    return (
        <div>
            <div
                style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
            >
                <button
                    onClick={() => submitQuestions(answers)}
                    style={{ width: 200, borderRadius: 10 }}
                >
                    Save
                </button>
            </div>
            <br />
            <div style={{ display: "flex", gap: 15 }}>
                <div style={{ ...containerStyle, width: "70%" }}>
                    <div style={{ display: "flex" }}>
                        <div style={{ width: "30%" }} />
                        <div
                            style={{
                                width: "60%",
                                display: "flex",
                                justifyContent: "flex-end",
                                gap: 50,
                            }}
                        >
                            <div style={{ width: "5%" }}>Potential</div>
                            <div style={{ width: "15%" }}>Actual</div>
                            <div style={{ width: "15%" }}>Comments</div>
                        </div>
                    </div>
                    <br />
                    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                        <div style={sectionStyle}>Impacts</div>
                        <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                            {impactQuestions.map((impact) => (
                                <InputRow
                                    key={impact.label}
                                    answers={answers}
                                    label={impact.label}
                                    options={["Low", "Medium", "High", "Very high"]}
                                    section="impacts"
                                    handleChange={handleChange}
                                    potential={impact.value}
                                />
                            ))}
                        </div>
                    </div>
                    <br />
                    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                        <div style={sectionStyle}>Dependencies</div>
                        <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                            {dependencyQuestions.map((dep) => (
                                <InputRow
                                    key={dep.label}
                                    answers={answers}
                                    label={dep.label}
                                    options={["Low", "Medium", "High", "Very high"]}
                                    section="dependencies"
                                    handleChange={handleChange}
                                    potential={dep.value}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        width: "30%",
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        gap: 15,
                    }}
                >
                    <div style={{ ...containerStyle, width: "100%" }}>
                        {questions.map((question) => (
                            <QuestionRow key={question} label={question} answers={answers} />
                        ))}
                    </div>

                    <div style={{ ...containerStyle, width: "100%" }}>
                        {!superUser && Object.keys(answers.new).length > 0 && (
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div style={{ width: "50%" }}></div>
                                <div
                                    style={{
                                        width: "50%",
                                        display: "flex",
                                        gap: 5,
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <div style={{ width: "50%" }}>2023</div>
                                    <div style={{ width: "50%" }}>2024</div>
                                </div>
                            </div>
                        )}
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <div style={{ width: superUser ? "100%" : "100%" }}>
                                {Object.keys(answers.new).length !== 0 &&
                                    Object.keys(answers.new).map((qs) => (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                width: "100%",
                                            }}
                                        >
                                            <div style={{ width: "50%" }}>{qs}</div>
                                            {!superUser && (
                                                <div
                                                    style={{
                                                        width: "50%",
                                                        display: "flex",
                                                        gap: 5,
                                                        justifyContent: "flex-end",
                                                    }}
                                                >
                                                    <div>
                                                        <textarea
                                                            onChange={(e) => {
                                                                setAnswers((prev) => ({
                                                                    ...prev,
                                                                    new: {
                                                                        ...prev.new,
                                                                        [qs]: {
                                                                            ...prev.new[qs],
                                                                            2023: e.target.value,
                                                                        },
                                                                    },
                                                                }));
                                                            }}
                                                            value={answers.new[qs]["2023"]}
                                                            style={{
                                                                borderRadius: 10,
                                                                padding: 0,
                                                                height: 30,
                                                                width: "100%",
                                                            }}
                                                        />
                                                    </div>
                                                    <div>
                                                        <textarea
                                                            onChange={(e) => {
                                                                setAnswers((prev) => ({
                                                                    ...prev,
                                                                    new: {
                                                                        ...prev.new,
                                                                        [qs]: {
                                                                            ...prev.new[qs],
                                                                            2024: e.target.value,
                                                                        },
                                                                    },
                                                                }));
                                                            }}
                                                            value={answers.new[qs]["2024"]}
                                                            style={{
                                                                borderRadius: 10,
                                                                padding: 0,
                                                                height: 30,
                                                                width: "100%",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                {superUser && (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "100%",
                                        }}
                                    >
                                        <label>Question</label>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                width: "100%",
                                            }}
                                        >
                                            <input
                                                onChange={async (e) => {
                                                    setNewQuestionToAdd(e.target.value);
                                                }}
                                                placeholder="Add a new question"
                                                name=""
                                                value={newQuestionToAdd}
                                            />
                                            <button
                                                style={{ width: "30%" }}
                                                onClick={async () => {
                                                    const newAnswers = {
                                                        ...answers,
                                                        new: {
                                                            ...answers.new,
                                                            [newQuestionToAdd]: { 2023: "", 2024: "" },
                                                        },
                                                    };
                                                    await submitQuestions(newAnswers);
                                                    setAnswers(newAnswers);
                                                    setNewQuestionToAdd("");
                                                }}
                                            >
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SiteInputTab;