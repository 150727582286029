/* eslint-disable @typescript-eslint/no-explicit-any */
import { apiGet } from "./http";
import { isThreatenedSpecies } from "./species";
import CryptoJS from "crypto-js";

if (
  !process.env.API_KEY ||
  !process.env.SECRET_KEY ||
  !process.env.KUYUA_APP_BACKEND_URL ||
  !process.env.GEO_SERVER_URL
) {
  throw new Error("Missing necessary environment variables.");
}

const encryptedToken = CryptoJS.AES.encrypt(
  process.env.API_KEY,
  process.env.SECRET_KEY
).toString();

const header = {
  headers: {
    Authorization: `Bearer ${encryptedToken}`,
  },
};

const formatRating = (rating: string) =>
  ({
    H: "High",
    M: "Medium",
    L: "Low",
    VH: "Very High",
  }[rating] || rating);

const formatImpactsAndDependencies = (data: any[], type: string) => {
  const result: { [key: string]: string } = {};
  ["VH", "H", "M", "L"].forEach((rating) => {
    result[`${type} (${formatRating(rating)})`] = "";
  });

  data.forEach(({ rating, values }) => {
    if (Array.isArray(values) && values.length > 0) {
      const key = `${type} (${formatRating(rating)})`;
      result[key] = values
        .filter((value: string) => value && !value.includes("undefined"))
        .map((value: string) => `• ${value.trim()}`)
        .join("\n");
    }
  });

  return result;
};

const backendURL = process.env.KUYUA_APP_BACKEND_URL;

export const sbm3 = async (site: ISite, project: IProject, team: ITeam) => {
  if (!site || !project || !team) {
    throw new Error("Missing site, project, or team information.");
  }

  const report = project.reports[project.latest_report_id] as IAssessmentReport;
  const url = `projects/${project.slug}/reports/${report.id}/site/${site.id}/detailed-view`;

  const siteDetailResponse = await apiGet<INewSiteDetailed>(team.slug, url);
  if (!siteDetailResponse?.data) {
    throw new Error("Failed to fetch site details.");
  }
  const siteDetail = siteDetailResponse.data;

  const {
    name: siteName,
    latlng,
    species,
    parameters,
    address,
    site_type,
  } = siteDetail;
  const formattedDate = new Date()
    .toLocaleDateString("en-GB")
    .replace(/\//g, "");
  const fullSiteName = `${siteName} ${formattedDate}`;

  const economic_activities = site?.economic_activities || [];
  const ecoIds = economic_activities
    .map((eca: { economic_activity_id: any }) => eca.economic_activity_id)
    .join(",");
  const activities = economic_activities
    .map(
      (e: { economic_activity_name: any }) => `• ${e.economic_activity_name}`
    )
    .join("\n");

  const fetchDepAndImp = async (endpoint: string) => {
    try {
      const response = await fetch(
        `${backendURL}/${endpoint}?economic_activities_ids=${ecoIds}`,
        header
      );
      if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      return data[endpoint]?.restructuredItems || [];
    } catch (error) {
      console.error(`Error fetching ${endpoint}:`, error);
      return [];
    }
  };

  const [dependencies, impacts] = await Promise.all([
    fetchDepAndImp("dependencies"),
    fetchDepAndImp("impacts"),
  ]);

  const siteAddress = `${address.street_address}, ${address.zip_code} ${address.city}, ${address.country}`;
  const sitePriorityScore = Math.min(
    Number(site.priority_score),
    100
  ).toFixed();

  const threatenedSpecies = species.filter(isThreatenedSpecies).length;

  const [BSACount, msaPercentage, msaDeltaPercentage] = await Promise.all([
    fetchBSA(latlng, parameters),
    fetchMSA(latlng),
    fetchMsaDelta(latlng),
  ]);

  const formattedImpacts = formatImpactsAndDependencies(impacts, "Impact");
  const formattedDependencies = formatImpactsAndDependencies(
    dependencies,
    "Dependency"
  );

  const impactsArray = impacts.flatMap((impact: any) => impact.values);

  const impactCategories = {
    landDegradation: [
      "ghg_emissions",
      "soil_pollutants",
      "water_pollutants",
      "freshwater_ecosystem_use",
      "marine_ecosystem_use",
      "terrestrial_ecosystem_use",
      "disturbances",
      "biological_alterations_interferences",
    ],
    sealingSoil: [
      "solid_waste",
      "other_resources_use",
      "terrestrial_ecosystem_use",
    ],
    desertification: [
      "soil_pollutants",
      "disturbances",
      "biological_alterations_interferences",
      "terrestrial_ecosystem_use",
      "water_use",
    ],
  };

  const formatImpactCategory = (category: string[]) =>
    impactsArray
      .filter((impact: string) => category.includes(impact))
      .map((impact: any) => ` • ${impact}`)
      .join("\n");

  const finalData = [
    ["Site Name", ` ${fullSiteName}`],
    ["Address", ` ${siteAddress}`],
    ["Country", ` ${address.country}`],
    ["Latitude", ` ${latlng.lat}`],
    ["Longitude", ` ${latlng.lng}`],
    ["Site Type", ` ${site_type.name}`],
    ["Activities on site", ` ${activities}`],
    ["Impact Radius (m)", ` ${site.radius}`],
    ["Materiality Threshold", " medium, high, very high"],
    ["Priority Score (1-100)", ` ${sitePriorityScore}`],
    [""],
    [
      "Ecological Status (0-100)",
      ` ${
        msaPercentage !== undefined ? Number(msaPercentage).toFixed(2) : "N/A"
      }`,
    ],
    [
      "Projected Change in Ecological Status",
      ` ${
        msaDeltaPercentage !== undefined
          ? Number(msaDeltaPercentage).toFixed(0) + "%"
          : "N/A"
      }`,
    ],
    [""],
    [
      "Activities impacting BSAs and Species",
      impacts
        .filter((impact: { rating: string }) =>
          ["VH", "H", "M"].includes(impact.rating)
        )
        .flatMap((impact: { values: any[] }) =>
          impact.values
            .filter((value: string) => value !== "ghg_emissions")
            .map((value: any) => ` • ${value}`)
        )
        .join("\n"),
    ],
    ["Biodiversity Sensitive Areas impacted", ` ${BSACount || "N/A"}`],
    ["Threatened Species", ` ${threatenedSpecies}`],
    [""],
    ["Impacts identified", " Potential", "Actual"],
    [
      "Impacts causing Land Degradation",
      formatImpactCategory(impactCategories.landDegradation),
      formatImpactCategory(impactCategories.landDegradation),
    ],
    [
      "Impacts causing Soil Sealing",
      formatImpactCategory(impactCategories.sealingSoil),
      formatImpactCategory(impactCategories.sealingSoil),
    ],
    [
      "Impacts causing Desertification",
      formatImpactCategory(impactCategories.desertification),
      formatImpactCategory(impactCategories.desertification),
    ],
    [""],
    ...Object.entries(formattedImpacts).map(([key, value]) => [
      key,
      ` ${value}`,
      ` ${value}`,
    ]),
    [""],
    ["Dependencies identified", " Potential", "Actual"],
    ...Object.entries(formattedDependencies).map(([key, value]) => [
      key,
      ` ${value}`,
      ` ${value}`,
    ]),
  ];

  return finalData;
};

async function fetchMsaDelta(latlng: { lat: number; lng: number }) {
  const url = `${process.env.GEO_SERVER_URL}/msa-delta?lat=${latlng.lat}&lng=${latlng.lng}`;
  return fetchData(url, "msa_delta", "Error fetching MSA Delta data");
}

async function fetchMSA(latlng: {
  lat: number;
  lng: number;
}): Promise<string | undefined> {
  const url = `${process.env.GEO_SERVER_URL}/msa?lat=${latlng.lat}&lng=${latlng.lng}`;
  return fetchData(url, "msa", "Error fetching MSA data");
}

async function fetchBSA(
  latlng: { lat: number; lng: number },
  parameters: any
): Promise<number | undefined> {
  const radius = parameters.inner_range_meters || 2000;
  const url = `${process.env.GEO_SERVER_URL}/protected-areas/circle?count=true&radius=${radius}&lng=${latlng.lng}&lat=${latlng.lat}`;
  return fetchData(url, "count", "Error fetching BSA count");
}

async function fetchData<T>(
  url: string,
  key: keyof T,
  errorMsg: string
): Promise<T[keyof T] | undefined> {
  try {
    const response = await fetch(url, header);
    const data = await response.json();
    return data[key];
  } catch (error) {
    console.error(`${errorMsg}:`, error);
    return undefined;
  }
}
