import * as React from 'react';
import { colors, style, StyleSheet } from "@/utils/style";
import { isThreatenedSpecies, isProtectedSpecies, isKeyStoneSpecies, t } from "@/utils";
import { StaticMap } from "@/lib/map";
import Dropdown from "@/utils/Dropdown";
import {booleanSort, getSortBy, SortOrder, speciesRoleSort, stringSort} from "@/utils/sort";
import { TableHeaderCell } from "@/lib";
import { SiteSpeciesItem } from "@/project/site/species/index";
import './index.scss';
import Checkbox from "@/utils/Checkbox";
import CryptoJS from 'crypto-js';
const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();
const header = {
  headers: {
    'Authorization': `Bearer ${encryptedToken}`
  }
};
interface SiteOverviewProps {
  site: INewSiteDetailed
  bbox: number[]
  layers: string[]
  onLayerLoad: (id: string, layer: IKeyAreaLayer) => void,
  onClick: (species: ISpecies, what: 'info' | 'map') => void
}

type SortType = 'species' | 'umbrella' | 'keystone' | 'protected' | 'threatened';

const SpeciesRoleDropdownItems = [
  { id: -1, label: t('ui.all'), apiLabel: '_all' },
  { id: 3, group: t('ui.habitat_role'), label: t('ui.apex_predator'), apiLabel: 'apex_predator' },
  { id: 4, group: t('ui.habitat_role'), label: t('ui.habitat_creator'), apiLabel: 'habitat_creator' },
  { id: 5, group: t('ui.habitat_role'), label: t('ui.seed_disperser'), apiLabel: 'seed_disperser' },
  { id: 6, group: t('ui.habitat_role'), label: t('ui.other'), apiLabel: 'other' },
];

export const SpeciesPage: React.FC<SiteOverviewProps> = ({ site, bbox, layers, onLayerLoad, onClick }) => {

  //const threatenedSpecies = site.species.filter(s => isThreatenedSpecies(s)).length;
  const protectedSpecies = site.species.filter(s => isProtectedSpecies(s)).length;
  //const keystoneSpecies = site.species.filter(s => isKeyStoneSpecies(s)).length;
  const [keystoneSpecies, setKeystoneSpeciesLength] = React.useState(0);
  const [threatenedSpecies, setThreatenedSpecies] = React.useState(0);
  const [totalSpecies, setSpeciesTotal] = React.useState(0);
  const [staticMapCenter, setStaticMapCenter] = React.useState(site.latlng);
  console.log("Outer site range -->", site.parameters.outer_range_meters);
  React.useEffect(() => {
    if (localStorage.getItem('totalSpecies') && totalSpecies === 0) {
      const _totalSpecies = JSON.parse(localStorage.getItem('totalSpecies'));
      const _threatenedSpecies = JSON.parse(localStorage.getItem('threatenedSpecies'));
      const _keystoneSpecies = JSON.parse(localStorage.getItem('keystoneSpecies'));

      setSpeciesTotal(_totalSpecies);
      setKeystoneSpeciesLength(_keystoneSpecies);
      setThreatenedSpecies(_threatenedSpecies);
    }
  }, [totalSpecies]);
  return (
    <div style={{ display: 'flex', flexDirection: 'row', flex: 1, gap: 30 }}>

      <div style={styles.wrapper}>
        <SpeciesTable site={site} onClick={onClick} sharedState={staticMapCenter} setSharedState={setStaticMapCenter} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, gap: 20 }}>
        <div style={styles.summaryHeader}>
          <HeaderKpi label={t('ui.total_species')} value={totalSpecies} />
          <HeaderKpi label={t('ui.threatened_species')} value={threatenedSpecies} />
          {/* <HeaderKpi label={t('ui.protected_species')} value={protectedSpecies} /> */}
          <HeaderKpi label={t('ui.keystone_species')} value={keystoneSpecies} />
        </div>
        <div style={{ position: 'relative', minHeight: 500 }}>
          <StaticMap
            bbox={bbox}
            center={staticMapCenter}
            circles={[
              { center: staticMapCenter, id: `site-${site.id}-inner`, radius: site.parameters.inner_range_meters },
              { center: staticMapCenter, id: `site-${site.id}-outer`, radius: site.parameters.outer_range_meters },
            ]}
            layers={layers}
            onLayerLoad={onLayerLoad}
            pins={[{ center: staticMapCenter, id: 'site-' + site.id, text: site.name ?? site.site_id }]}
            rasters={{}}
            zoom={7} />
        </div>
      </div>
    </div>
  );
};

const SpeciesTable: React.FC<{site: INewSiteDetailed, onClick:(species: ISpecies, what: 'info' | 'map') => void, sharedState, setSharedState}> = ({ site, onClick, sharedState, setSharedState }) => {

  const [sortType, setSortType] = React.useState<SortType>('species');
  const [sortOrder, setSortOrder] = React.useState<SortOrder>('desc');

  const [speciesTypeIds, setSpeciesTypeIds] = React.useState<number[]>([]);
  const [roleIds, setRoleIds] = React.useState<number[]>([]);
  const speciesTypes = [{ id: 1, label: t('ui.protected_species')}, { id: 2, label: t('ui.threatened_species')}];
  const [speciesAlldata, setSpecies] = React.useState({updatedSpeciesList:[]});
  const fetchSpecies = async () => {
    const url = `${process.env.GEO_SERVER_URL}/species?decimalLatitude=${site.latlng.lat}&decimalLongitude=${site.latlng.lng}`
    console.log(url);
    try {
      const response = await fetch(url, header);
      const data = await response.json();
      console.log('species ===>>> ', data);
      if (speciesAlldata.updatedSpeciesList.length === 0) {
        setSpecies(data);
      }

    } catch (error) {
      console.error('Error fetching species:', error);
    }
  }
  React.useEffect(() => {
    fetchSpecies();
  }, []);
  const onSort = (order: SortOrder, type: SortType) => {
    setSortType(type);
    setSortOrder(order);
  };
  const conservation_statuses = ['CR', 'EN', 'VU'];

  const filteredSpeciesList = React.useMemo(() => {
    let species = [].concat(speciesAlldata?.updatedSpeciesList).filter(s => s.is_keystone_species === true);
    //console.log('speciesAlldata XXXXXXXX ', species);

    if (speciesTypeIds.length > 0) {

      const selectedSpeciesLabels = speciesTypeIds.map(id => speciesTypes.find(c => c.id === id)?.label);

      if (selectedSpeciesLabels.includes(t('ui.protected_species'))) {
        species = species.filter(s => !conservation_statuses.includes(s.conservation_status));
      }

      if (selectedSpeciesLabels.includes(t('ui.threatened_species'))) {
        species = species.filter(s => conservation_statuses.includes(s.conservation_status));
      }
    }

    const selectedRoleLabels = SpeciesRoleDropdownItems
        .filter(selectedRole => roleIds.includes(selectedRole.id))
        .map(filteredRole => filteredRole.apiLabel);

    let filteredSpecies = selectedRoleLabels.length === 0 ? species : []

    selectedRoleLabels.forEach((selectedRoleLabel) => {

      if (['apex_predator', 'habitat_creator', 'seed_disperser'].includes(selectedRoleLabel)) {
        filteredSpecies = filteredSpecies.concat(species.filter(s => s.is_keystone_species === true));
        console.log('filteredSpecies INSIDE XXXXXXXX ', filteredSpecies);
      } else if (selectedRoleLabel === 'other') {
        filteredSpecies = filteredSpecies.concat(species.filter(s => s.is_keystone_species === false));
      }
    });

    species = filteredSpecies;
    

    return species.sort((a, b) => {
      if (sortType === 'species') {
        return stringSort(a.name, b.name, sortOrder);
      }
      else if (sortType === 'protected') {
        return booleanSort(!conservation_statuses.includes(a.conservation_status), !conservation_statuses.includes(b.conservation_status), sortOrder);
      }
      else if (sortType === 'threatened') {
        return booleanSort(conservation_statuses.includes(a.conservation_status), conservation_statuses.includes(b.conservation_status), sortOrder);
      } else {
        return speciesRoleSort(a, b, sortOrder);
      }
    });
  }, [speciesTypeIds, speciesAlldata.updatedSpeciesList, roleIds, sortOrder, sortType]);

  

  const handleSetRole = (ids: number[]): void => {

    const oldSelect = roleIds;
    const newSelect = ids.filter(newId => !oldSelect.includes(newId));

    if (ids.length === 0 || newSelect.includes(-1)) {

      setRoleIds([]);
    } else {

      setRoleIds(ids)
    }
  };

  const handleSetSpeciesType = (selectedId: number, check: boolean): void => {

    if (check) {

      setSpeciesTypeIds(prev => [...prev, selectedId]);
    } else {

      setSpeciesTypeIds(speciesTypeIds.filter(id => id !== selectedId));
    }
  };
  const OverrideStaticMap = (specie): void => {
    const latlng = {
      lat: specie?.decimalLatitude,
      lng: specie?.decimalLongitude
    }
    if(sharedState.lat !== latlng.lat && sharedState.lng !== latlng.lng){
      setSharedState(latlng);
    }
  }
  const HandleSpecieRank = (specie): String => {
    if (specie.apex_predator_rank > 0) {
      return t('ui.apex_predator');
    }
    else if (specie.habitat_creator_rank > 0 && (specie.seed_disperser_rank ?? 0) < specie.habitat_creator_rank) {
      return t('ui.habitat_creator');
    }
    else if (specie.seed_disperser_rank > 0 && (specie.habitat_creator_rank ?? 0) < specie.seed_disperser_rank) {
      return t('ui.seed_disperser');
    } else {
      return t('ui.other');
    }
  }
  return (
    <>
    <div style={{ ...style.flexRow, ...styles.filterBar }}>
      <Dropdown
        placeholder={'key-stone species'}
        dropdownStyle={{ padding: '5px 10px', marginBottom: 'unset', fontSize: 13, height: 'unset' }}
        listDropdownStyle={{ top: "unset" }}
        handler={handleSetRole}
        options={SpeciesRoleDropdownItems}
        selected={roleIds}
        flat
        multiple
        selectedRight
      />
      <div style={{ ...style.flexRow, justifyContent: "right", flex: 2 }}>
          {speciesTypes.map((species, i) => (
            <div id={"species_" + species.id} style={{ marginRight: 10 }}>
              <Checkbox
                checked={speciesTypeIds.includes(species.id)}
                label={species.label}
                checkHandler={(check:boolean) => handleSetSpeciesType(species.id, check)}
              />
            </div>
          ))}
      </div>
    </div>

      <div className={'table-container'}>
        <table className="s-table s-table-fixed species-table">
          <thead>
          <tr>
            <TableHeaderCell
              onClick={(arg: SortOrder) => onSort(arg, 'species')}
              sortedBy={getSortBy(sortType, 'species', sortOrder)}
              label={t('ui.species')}
            />
            <TableHeaderCell
              label={t('ui.keystone_species')}
              onClick={(arg: SortOrder) => onSort(arg, 'keystone')}
              sortedBy={getSortBy(sortType, 'keystone', sortOrder)}
            />
            <TableHeaderCell
              label={t('ui.protected_species')}
              onClick={(arg: SortOrder) => onSort(arg, 'protected')}
              sortedBy={getSortBy(sortType, 'protected', sortOrder)}
            />
            <TableHeaderCell
              label={t('ui.threatened_species')}
              onClick={(arg: SortOrder) => onSort(arg, 'threatened')}
              sortedBy={getSortBy(sortType, 'threatened', sortOrder)}
              textAlign={'end'}
            />
          </tr>
          </thead>
          <tbody>
          {filteredSpeciesList.map((s) => 
            <tr>
              <td>
                <div>
                  <a className="s-link" onClick={()=> OverrideStaticMap(s)}>{s.name}</a>
                </div>
                <div className="s-table-sublabel">{s.common_name || '—'}</div>
              </td>
              <td>
                <div style={{ textAlign: "center" }}>{HandleSpecieRank(s)}</div>
                <div className="s-table-sublabel">&nbsp;</div>
              </td>
              <td style={{ textAlign: "center" }}>
                <Checkbox checked={!conservation_statuses.includes(s.conservation_status)} readonly={true} checkHandler={() => { }} />
              </td>
              <td style={{ textAlign: "center" }}>
                <Checkbox checked={conservation_statuses.includes(s.conservation_status)} readonly={true} checkHandler={() => { }} />
              </td>
            </tr>
          )}
          </tbody>
        </table>
      </div>
  </>
  );
};


export const HeaderKpi: React.FC<{ label: string, value: number | string }> = ({ label, value }) => {

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 10, alignItems: 'center' }}>
      <span style={{ fontSize: 12, textTransform: 'uppercase' }}>{label}</span>
      <span style={{ fontSize: 30, color: colors.brightBlue }}>{value}</span>
    </div>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    width: '50%',
    borderRadius: 10,
    border: `1px solid ${colors.brightBlue}`,
    padding: 10,
    backgroundColor: colors.darkBlue,
  },
  summaryHeader: {
    height: 90,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.darkBlue,
    borderRadius: 10,
    padding: '5px 20px',
  },
  filterBar: {
    borderRadius: 10,
    backgroundColor: colors.opaqueWhite,
    padding: 10,
    alignItems: 'center',
    gap: 10,
  },
  export: {
    height: '100%',
    borderRadius: 20,
    backgroundColor: 'transparent',
    opacity: 1,
    padding: '5px 10px',
    color: colors.white,
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    border: `1px solid ${colors.white}`,
    fontSize: 13,
  },
});
